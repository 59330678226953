import React from 'react';

import {
  MenuButton,
  Breadcrumbs,
} from '@components/header';

import { Bookmarks } from '@layouts/bookmarks/components/bookmarks';

import { SettingsConnect } from './settings.connect';
import { ShareConnect } from './share/share.connect';
import { LayoutSwitcherConnect } from './layoutSwitcher.connect';

import s from './header.style';

interface Path {
  id: number;
  title: string;
  route: string;
  loading: boolean;
}

interface HeaderProps {
  item: {
    type: string;
    information: {
      status: 'onModeration' | 'published';
      hasChanges: boolean;
    }
  };
  path: Path[];
}

export class Header extends React.PureComponent<HeaderProps> {

  renderStatus() {
    if (this.props.item.type === 'company') {
      return <s.CompanyStatus>Командная</s.CompanyStatus>;
    }

    const isAuthorPackage = this.props.item.type === 'author';
    if (!isAuthorPackage) {
      return null;
    }

    if (this.props.item.information.status === 'onModeration') {
      return <s.GreyStatus>На модерации</s.GreyStatus>;
    }

    if (this.props.item.information.status === 'published') {
      return <s.BlueStatus>Опубликована</s.BlueStatus>;
    }

    return null;
  }

  renderStatusTitle() {
    const isAuthorPackage = this.props.item.type === 'author';
    if (!isAuthorPackage) {
      return null;
    }

    if (!this.props.item.information.hasChanges) {
      return null;
    }

    return <s.StatusTitle>Есть неопубликованные изменения</s.StatusTitle>;
  }

  renderShare = () => {
    const isAuthorPackage = this.props.item.type === 'author';
    if (isAuthorPackage) {
      return null;
    }

    return (
      <s.Action>
        <ShareConnect />
      </s.Action>
    );
  };

  renderActions = () => (
    <s.Actions>
      {this.renderShare()}
      <s.Action>
        <Bookmarks />
      </s.Action>
      <s.Action>
        <LayoutSwitcherConnect />
      </s.Action>
      <s.Action>
        <SettingsConnect />
      </s.Action>
    </s.Actions>
  );

  render() {
    return (
      <s.Root>
        <s.Menu>
          <MenuButton />
        </s.Menu>
        <s.Breadcrumbs>
          <Breadcrumbs breadcrumbs={this.props.path} />
          {this.renderStatus()}
          {this.renderStatusTitle()}
        </s.Breadcrumbs>
        {this.renderActions()}
      </s.Root>
    );
  }

}

export default Header;
