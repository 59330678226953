import { Dispatch } from 'redux';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { selectDocumentsSort } from '@layouts/documents/redux/selectDocumentsSort';
import type { SortListItem } from '@layouts/documents/redux/state';
import { getActiveItem } from '@layouts/documents/utils';

import type { RequestParams } from './fetchDocuments.http';
import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { httpFetchSearchDocuments } from './fetchDocuments.http';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';
import { clearPages } from './clearPages';

interface Params {
  searchId?: number,
  pageNumber?: number,
  sortData?: SortListItem,
}

export class FetchSearchDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  private sourceId: number;

  private pageNumber: number;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.sourceId = null;
    this.pageNumber = null;
  }

  public async invoke(params: Params) {
    const searchResult = this.getState().searchResult;
    this.sourceId = params.searchId || searchResult.id;
    this.pageNumber = params.pageNumber || searchResult.documents.currentPageNumber;

    this.sendMarketingEvent();

    this.dispatch(pageLoadingStart.createAction());

    if (params.sortData) {
      await this.changeSort(params.sortData);

      return;
    }

    // @ts-ignore
    const hasPage = searchResult.documents.pages[this.pageNumber];

    if (hasPage) {
      this.dispatchChangePageNumber();

      return;
    }

    await this.getNewPage();

  }

  private sendMarketingEvent() {
    sendAmplitudeData('go to page_search results', { page: this.pageNumber });
    Userpilot.track('go to page_search results', { page: this.pageNumber });
  }

  private async getNewPage() {
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      sort: getActiveItem<SortListItem>(this.getState().documents.documentsSort),
    };

    const response = await this.fetchDocuments(requestParams);
    this.dispatchGetDocumentsSucceed(response);
    this.dispatchChangePageNumber();
  }

  private async changeSort(sort) {
    this.dispatch(selectDocumentsSort.createAction(sort));
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      sort,
    };

    const response = await this.fetchDocuments(requestParams);
    this.dispatch(clearPages.createAction());
    this.dispatchGetDocumentsSucceed(response);
    this.dispatchChangePageNumber();
  }

  private async fetchDocuments(requestParams: RequestParams) {
    try {
      return await httpFetchSearchDocuments(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetDocumentsSucceed(searchResult: any) {
    const actionData = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      result: searchResult,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber() {
    const actionData = {
      pageNumber: this.pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchSearchDocumentsThunk(dispatch, params: Params) {
  const thunk = new FetchSearchDocumentsThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  await thunk.invoke(params);
}

export default fetchSearchDocumentsThunk;
