import React from 'react';

import { ListItem } from '@components/menu/listItem';

import { UpdatePackageNameConnect } from './updatePackageName/updatePackageName.connect';
import { RemovePackageConnect } from './removePackage/removePackage.connect';
import { MenuConnect as AuthorMenuConnect } from '../createPackage/menu.connect';

import s from './packageMenu.style';

interface MainLevel {
  title: string
  onClick: () => void
  hasArrow: boolean
}

export type PackageId = number;

interface Props {
  id: PackageId;
  isOwner: boolean;
  onLevelChange: (params: { wide: boolean; level: number }) => void;
  onClickOutside?(): void;
  closePopover: () => void;
}

enum ViewTypes {
  rename = 'RENAME',
  remove = 'REMOVE',
  default = '',
}

enum Level {
  main,
  edit,
}

export class PackageMenu extends React.PureComponent<Props> {

  state = {
    view: {
      type: ViewTypes.default,
      level: Level.main,
    },
  };

  setView(type: ViewTypes, level: number) {
    this.setState({
      view: {
        type,
        level,
      },
    });
  }

  handleSetViewToMain = () => {
    this.props.onLevelChange({ wide: false, level: Level.main });
    this.setView(ViewTypes.default, Level.main);
  };

  handleSetRenameLevelToModify = () => {
    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.rename, Level.edit);
  };

  handleSetRemoveLevelToModify = () => {
    if (!this.props.isOwner) {
      return;
    }

    this.props.onLevelChange({ wide: true, level: Level.edit });
    this.setView(ViewTypes.remove, Level.edit);
  };

  stopPropagation = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  handleCloseAuthorPackage = () => {
    if (this.props.onClickOutside) {
      this.props.onClickOutside();
    }

    this.props.closePopover();
  };

  renderMainLevel(renderData: MainLevel) {
    const firstLevel = this.state.view.level === Level.main;

    if (!firstLevel) {
      return null;
    }

    return <ListItem {...renderData} />;
  }

  renderMainRemove() {
    const removeData = {
      title: 'Удалить подборку',
      onClick: this.handleSetRemoveLevelToModify,
      hasArrow: true,
      disable: !this.props.isOwner,
    };

    return this.renderMainLevel(removeData);
  }

  renderMainAuthorPackage() {
    return <AuthorMenuConnect id={this.props.id} onClose={this.handleCloseAuthorPackage} />;
  }

  renderMainRename() {
    const renameData = {
      title: 'Переименовать подборку',
      onClick: this.handleSetRenameLevelToModify,
      hasArrow: true,
    };

    return this.renderMainLevel(renameData);
  }

  renderModifyRenameLevel() {
    return (
      <UpdatePackageNameConnect
        id={this.props.id}
        onGoBack={this.handleSetViewToMain}
      />
    );
  }

  renderModifyRemoveLevel() {
    return (
      <RemovePackageConnect
        onGoBack={this.handleSetViewToMain}
        id={this.props.id}
        onSetViewToMain={this.handleSetViewToMain}
        onClosePopover={this.props.closePopover}
      />
    );
  }

  renderSecondLevel() {
    const isRemove = this.state.view.type === ViewTypes.remove;
    if (isRemove) {
      return this.renderModifyRemoveLevel();
    }

    const isRename = this.state.view.type === ViewTypes.rename;
    if (isRename) {
      return this.renderModifyRenameLevel();
    }

    return null;
  }

  renderLevels() {
    const firstLevel = this.state.view.level === Level.main;
    if (firstLevel) {
      return (
        <s.List>
          {this.renderMainRename()}
          {this.renderMainAuthorPackage()}
          {this.renderMainRemove()}
        </s.List>
      );
    }

    return (
      <s.Edit>
        {this.renderSecondLevel()}
      </s.Edit>
    );
  }

  render() {
    return (
      <s.Root onClick={this.stopPropagation}>
        {this.renderLevels()}
      </s.Root>
    );
  }

}

export default PackageMenu;
