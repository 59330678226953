import { RESTPath } from '@router/path.rest';
import http from '@http';

import type {
  Id,
  AccessId,
  UserDTO,
} from './state';

export interface UsersDTO {
  defaultAccess: AccessId;
  result: UserDTO[];
}

export function httpFetchUsers(sourceId: Id, query): Promise<UsersDTO> {
  const url = RESTPath.packages.getUsers({ sourceId, query });
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchUsers;
