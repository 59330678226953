import { connect } from 'react-redux';

import { AddNewBlock } from './addNewBlock';

import { blocks } from './utils';

const mapSateToProps = (state) => {
  const { caselook, regulation } = state.currentUser.licenses;

  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;
  const multiLicense = caselookLicense && monitoringLicense;

  if (!multiLicense) {
    return {
      blocks: blocks.filter((item, index) => index !== 5),
    };

  }

  return {
    blocks,
  };
};

export const AddNewBlockConnect = connect(
  mapSateToProps,
)(AddNewBlock);

export default AddNewBlockConnect;
