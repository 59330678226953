import React, { Component } from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';

import type { Location } from 'history';

import { RoutePath } from '@router/path';

function handleUserHasPermission(props) {
  if (props.userHasPermission) {
    return null;
  }

  const { caselook, regulation } = props.currentUser.licenses;
  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;
  let pathname;

  if (caselookLicense) {
    pathname = RoutePath.search;
  }

  if (monitoringLicense) {
    pathname = RoutePath.regulations;
  }

  if (caselookLicense && monitoringLicense) {
    pathname = RoutePath.search;
  }

  return {
    to: {
      push: false,
      pathname,
    },
  };
}

function handleUnauthenticated(props) {
  if (props.userAuthenticated) {
    return null;
  }

  const { location } = props;
  const logout = (location.state && location.state.logout) || null;
  const to = {
    push: false,
    pathname: RoutePath.login,
    state: {
      logout,
      from: location,
    },
  };

  return {
    to,
  };
}

function handleUnauthorized(props) {
  if (props.userAuthorized) {
    return null;
  }

  const to = {
    push: false,
    pathname: RoutePath.search,
  };

  return {
    to,
  };
}

function handleUserIsActive(props) {
  const isPaymentSelectPath = props.location.pathname === RoutePath.paymentSelect;
  if (props.currentUser.paymentInProgress && isPaymentSelectPath) {
    return {
      to: {
        push: false,
        pathname: RoutePath.profile,
      },
    };
  }

  if (isPaymentSelectPath) {
    return null;
  }

  if (props.userIsActive) {
    return null;
  }

  const to = {
    push: false,
    pathname: RoutePath.profile,
  };

  return {
    to,
  };
}

interface AuthRoutePropsI {
  path: string;
  exact: boolean;
  currentUser: any;
  userAuthorized: boolean;
  userAuthenticated: boolean;
  userHasPermission: boolean;
  component: React.ComponentType<any>;
  location: Location;
}

export class AuthRoute extends Component<AuthRoutePropsI> {

  private chain = [];

  constructor(props) {
    super(props);

    this.chain.push(handleUnauthenticated);
    this.chain.push(handleUnauthorized);
    this.chain.push(handleUserIsActive);
    this.chain.push(handleUserHasPermission);
  }

  render() {
    let result;
    for (let i = 0; i < this.chain.length; i += 1) {
      const handler = this.chain[i];
      result = handler(this.props);
      if (result) {
        break;
      }
    }

    if (result && (this.props.path !== result.to.pathname)) {
      return <Redirect to={result.to} />;
    }

    return (
      <Route
        exact={this.props.exact}
        location={this.props.location}
        component={this.props.component}
        path={this.props.path}
      />
    );

  }

}

export default AuthRoute;
