import { Dispatch } from 'redux';

import type { SortListItem } from '@layouts/documents/redux/state';
import { selectDocumentsSort } from '@layouts/documents/redux/selectDocumentsSort';
import { getActiveItem } from '@layouts/documents/utils';
import { clearPages } from './clearPages';
import { httpFetchAuthorPackageDocuments } from './fetchDocuments.http';

import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

interface Params {
  id?: number,
  pageNumber?: number,
  sortData?: SortListItem
}

interface RequestParams {
  sourceId: number,
  pageNumber: number,
  sortData?: SortListItem
}

export class FetchDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  private sourceId: number;

  private pageNumber: number;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.sourceId = null;
    this.pageNumber = null;
  }

  public async invoke(params: Params) {
    const authorPackages = this.getState().authorPackages;
    this.sourceId = params.id || authorPackages.id;
    this.pageNumber = params.pageNumber || authorPackages.documents.currentPageNumber;

    this.dispatch(pageLoadingStart.createAction());

    if (params.sortData) {
      await this.changeSort(params.sortData);

      return;
    }

    const hasPage = authorPackages.documents.pages[this.pageNumber];
    if (hasPage) {
      this.dispatchChangePageNumber();

      return;
    }

    await this.getNewPage();
  }

  private async getNewPage() {
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      sort: getActiveItem<SortListItem>(this.getState().documents.documentsSort),
    };

    const response = await this.fetchDocuments(requestParams);
    this.dispatchGetDocumentsSucceed(response);
    this.dispatchChangePageNumber();
  }

  private async changeSort(sort) {
    this.dispatch(selectDocumentsSort.createAction(sort));
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      sort,
    };

    const response = await this.fetchDocuments(requestParams);
    this.dispatch(clearPages.createAction());
    this.dispatchGetDocumentsSucceed(response);
    this.dispatchChangePageNumber();
  }

  private async fetchDocuments(requestParams: RequestParams) {
    try {
      return await httpFetchAuthorPackageDocuments(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchGetDocumentsSucceed(result: any) {
    const actionData = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      result,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber() {
    const actionData = {
      pageNumber: this.pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export function fetchDocumentsThunk(dispatch, params) {
  const thunk = new FetchDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default fetchDocumentsThunk;
