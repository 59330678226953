import React from 'react';
import { Switch } from 'react-router-dom';

import { RouteSearchDocuments } from '@pages/searchResult/documents/documents.route';
import { RouteSearchCourts } from '@pages/searchResult/courts/courts.route';
import { RouteSearchAnalysis } from '@pages/searchResult/analysis/analysis.route';
import { RouteSearchLaws } from '@pages/searchResult/law/law.route';

interface Props {
  onResetToInitial(): void;
}

class SearchResult extends React.PureComponent<Props> {

  componentWillUnmount() {
    this.props.onResetToInitial();
  }

  render() {
    return (
      <Switch>
        {RouteSearchDocuments}
        {RouteSearchCourts}
        {RouteSearchLaws}
        {RouteSearchAnalysis}
      </Switch>
    );
  }

}

export default SearchResult;
