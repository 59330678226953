import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import { License } from '@pages/userPages/redux/state';

import {
  resolveRouteRoles,
  resolveRoutePermissions,
  LicenseType,
} from '@router/pathPermissions';

export interface User {
  id: number;
  name: string;
  role: string;
  licenses: {
    caselook: License;
    regulation: License;
  }
}

export const userAuthenticated = (user: User) => user.id || false;

export const userIsActive = (user: User) => {
  const { caselook, regulation } = user.licenses;

  return caselook.isActive || regulation.isActive;
};

export const userAuthorized = (
  user: User,
  location: string | { pathname: string },
) => {
  const { role } = user;
  const roles = resolveRouteRoles(location);

  return roles.includes(role);
};

export const userHasPermission = (
  user: User,
  location: string | { pathname: string },
) => {
  const { licenses } = user;

  const caselookLicense = licenses.caselook.isActive;
  const caselookExpired = licenses.caselook.isExpired;
  const monitoringLicense = licenses.regulation.isActive;
  let license;

  if (caselookLicense) {
    license = LicenseType.caselook;
  }

  if (monitoringLicense) {
    license = LicenseType.monitoring;
  }

  if (caselookLicense && monitoringLicense) {
    license = LicenseType.multi;
  }

  if (caselookExpired) {
    license = LicenseType.caselookExpired;
  }

  if (caselookExpired && monitoringLicense) {
    license = LicenseType.caselookExpiredMulti;
  }

  const permissions = resolveRoutePermissions(location);

  return permissions.includes(license);
};

export function formatName(user: User) {
  return isEmpty(user) || isNull(user.id) ? 'unnamed' : user.name;
}

export default userAuthenticated;
