import React from 'react';

import type { Item } from '@layouts/documents/components/documentsSortMenu';

import s from '@layouts/documents/components/menu.style';

interface Props {
  item: Item;
  onSelect(value): void;
}

export const MenuItem = ({ item, onSelect }: Props) => {

  const handleSelect = () => {
    onSelect(item);
  };

  return (
    <s.ListItem
      onClick={handleSelect}
      selected={item.isActive}
    >
      <s.Title>
        {item.title}
      </s.Title>
    </s.ListItem>
  );
};

export default MenuItem;
