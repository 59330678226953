import { connect } from 'react-redux';

import { TabBar } from '@components/tabBar/tabBar';

const tabs = [
  {
    id: 'search',
    title: 'Судебные акты',
  },
  {
    id: 's_law',
    title: 'ЗАКОНОДАТЕЛЬСТВО',
  },
];

const mapStateToProps = (state) => {
  const { caselook, regulation } = state.currentUser.licenses;

  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    activeId: 's_law',
    tabs,
    renderNavigation: !!multiLicense || caselookLicense,
  };
};

const mapDispatchToProps = () => ({
  onTabClick(id) {
    window.location.hash = `#/${id}`;
  },
});

export const TabBarConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabBar);

export default TabBarConnect;
