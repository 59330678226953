import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';

import { FetchSearchResultDocumentsSettingsThunk } from './redux/fetchSettings.thunk';
import { fetchSearchDocumentsThunk } from './redux/fetchDocuments.thunk';
import { changePageNumber } from './redux/changePageNumber';
import { toggleSelectionRule } from './redux/toggleSelectionRule';

import { Documents } from './documents';

const mapStateToProps = (state, ownProps) => {
  const { sourceId } = getParamsFromUrl(ownProps);
  const {
    layout,
    loading,
    pageLoading,
    currentPageNumber,
    pages,
  } = state.searchResult.documents;

  const items = pages[currentPageNumber] ? pages[currentPageNumber].items : [];

  return {
    id: sourceId,
    isEmpty: !items.length,
    layout,
    loading,
    pageLoading,
    processingStatus: state.searchResult.processingStatus,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchSettings() {
    const thunk = new FetchSearchResultDocumentsSettingsThunk(dispatch);
    thunk.invoke();
  },
  onResetPageNumber() {
    dispatch(changePageNumber.createAction({
      pageNumber: 1,
      globalPageNumber: 1,
    }));
  },
  onFetch() {
    const { sourceId } = getParamsFromUrl(ownProps);
    fetchSearchDocumentsThunk(dispatch, { searchId: sourceId });
  },
  onChangeSelectionRule() {
    dispatch(toggleSelectionRule.createAction());
  },
});

export const DocumentsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Documents);

export default DocumentsConnect;
