import { connect } from 'react-redux';

import { Share } from './share';

const mapStateToProps = (state) => {
  const {
    activeItemId,
  } = state.packages.packages;

  const shouldRender = true;

  return {
    id: activeItemId,
    disabled: !activeItemId,
    shouldRender,
  };
};

export const ShareConnect = connect(
  mapStateToProps,
)(Share);

export default ShareConnect;
