import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  width: 32px;
  height: 32px;
  background: ${(props) => props.theme.colors.defaultButton};

  path {
    transform: scale(0.33);
  }
`;

export default style;
