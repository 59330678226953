import { clearPagesReduce } from '@pages/searchResult/documents/redux/clearPages.reduce';

const actionType = 'AUTHOR_PACKAGES/RESULT/DOCUMENTS/RESET_PAGES';

const createAction = () => ({
  type: actionType,
});

export const clearPages = {
  actionType,
  createAction,
  reduce: clearPagesReduce,
};

export default clearPages;
