export const clearPagesReduce = (state) => {
  if (!state) {
    return state;
  }

  return {
    ...state,
    documents: {
      ...state.documents,
      previewDocument: {
        currentPageNumber: 1,
        documentId: null,
      },
      pages: {},
    },
  };
};

export default clearPagesReduce;
