import http from '@http';
import { RESTPath } from '@router/path.rest';

export function httpFetchDocumentsSort() {
  const request = {
    url: RESTPath.documents.bootstrap.sort,
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(http.parseResponse);
}

export default httpFetchDocumentsSort;
