import { connect } from 'react-redux';

import { Bookmarks } from './bookmarks';

const mapStateToProps = (state) => {
  const { caselook, regulation } = state.currentUser.licenses;

  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    shouldRender: multiLicense || caselookLicense,
  };
};

export const BookmarksConnect = connect(
  mapStateToProps,
)(Bookmarks);

export default BookmarksConnect;
