import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Button } from '@pages/searchResult/components/button/button';

import { Menu } from './menu';

import s from './documentsSortMenu.style';

export interface Item {
  isActive: boolean;
  title: string;
}

interface Props {
  items: any[];
  title: string;

  onSelect(value): void;
  onClick?(): void;
}

export const DocumentsSortMenu = (props: Props) => {

  if (!props.items.length) {
    return null;
  }

  const handleClick = () => {
    if (!props.onClick) {
      return;
    }

    props.onClick();
  };

  const renderInput = (p) => (
    <Button
      {...p}
      title={props.title}
      onClick={handleClick}
      needUppercase={false}
    />
  );

  const renderMenu = (p) => (
    <Menu
      {...p}
      items={props.items}
      onSelect={props.onSelect}
    />
  );

  return (
    <s.Row>
      <s.Container>
        <BaseDropdown
          // @ts-ignore
          usePortal
          placement="bottom-start"
          renderInput={renderInput}
          renderMenu={renderMenu}
        />
      </s.Container>
    </s.Row>
  );

};

export default DocumentsSortMenu;
