import {
  Id,
  Access,
  StateBuilder,
} from './state';

export type ActionData = {
  userId: Id;
  access: Access;
};

export interface Action {
  type: string;
  data: ActionData;
}

export const changeAccessReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.users);
  newState.setUserAccess(action.data.userId, action.data.access);

  return {
    ...state,
    users: newState.getState(),
  };
};

export default changeAccessReduce;
