import React from 'react';

import { Loader } from '@components/loader';

import s from './licenseCassette.style';

interface ItemProps {
  title: string;
  value: string;
  alert: boolean;
}

const Item: React.FC<ItemProps> = (props: ItemProps) => (
  <s.Item>
    <s.ItemTitle>{props.title}</s.ItemTitle>
    <s.ItemValue alert={props.alert}>{props.value}</s.ItemValue>
  </s.Item>
);

interface LicenseCassetteProps {
  caselook: {
    shouldRenderProlongControl: boolean;
    paymentInProgress?: boolean;
    items: ItemProps[];
  };
  regulation: {
    isActive: boolean;
    items: ItemProps[];
  }
}

export class LicenseCassette extends React.PureComponent<LicenseCassetteProps> {

  handleHeaderActionClick = () => {
    window.location.href = '#/paymentselect';
  };

  renderItem(item) {
    return <Item key={item.id} {...item} />;
  }

  renderHeaderTitle(title) {
    return <s.HeaderTitle>{`Лицензия ${title}`}</s.HeaderTitle>;
  }

  renderRegulationAction() {
    if (this.props.regulation.isActive) {
      return null;
    }

    return (
      <s.HeaderActionLink
        target="_blank"
        href="https://about.pravo.tech/npa#form"
      >
        Узнать о продукте
      </s.HeaderActionLink>
    );
  }

  renderHeaderAction() {
    if (!this.props.caselook.shouldRenderProlongControl) {
      return null;
    }

    if (this.props.caselook.paymentInProgress) {
      return (
        <s.HeaderLodaer>
          <s.Loader>
            <Loader size={16} />
          </s.Loader>
          <s.HeaderLoaderTitle>Проверка статуса платежа</s.HeaderLoaderTitle>
        </s.HeaderLodaer>
      );
    }

    return (
      <s.HeaderAction
        onClick={this.handleHeaderActionClick}
      >
        Оплатить
      </s.HeaderAction>
    );
  }

  renderCaselook() {
    return (
      <s.License>
        <s.Header>
          {this.renderHeaderTitle('«Caselook»')}
          <s.HeaderPayment>
            {this.renderHeaderAction()}
          </s.HeaderPayment>
        </s.Header>
        <s.Info>
          {this.props.caselook.items.map(this.renderItem)}
        </s.Info>
      </s.License>
    );
  }

  renderRegulation() {
    return (
      <s.License>
        <s.Header>
          {this.renderHeaderTitle('«Управляй (НПА)»')}
          <s.HeaderPayment>
            {this.renderRegulationAction()}
          </s.HeaderPayment>
        </s.Header>
        <s.Info>
          {this.props.regulation.items.map(this.renderItem)}
        </s.Info>
      </s.License>
    );
  }

  render() {
    return (
      <>
        {this.renderCaselook()}
        <s.Separator />
        {this.renderRegulation()}
      </>
    );
  }

}

export default LicenseCassette;
