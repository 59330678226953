import { resetDocumentsSortSelectReduce } from './resetDocumentsSortSelect.reduce';

const actionType = 'DOCUMENTS/RESET_SELECT_SORT';

const createAction = () => ({
  type: actionType,
});

export const resetDocumentsSortSelect = {
  actionType,
  createAction,
  reduce: resetDocumentsSortSelectReduce,
};

export default resetDocumentsSortSelect;
