import React from 'react';

import { Loader } from '@components/loader';

import s from '@layouts/shareMenu/users.style';

import { RoleConnect } from './role.connect';
import { UserConnect } from './user.connect';

export type Id = number;

interface UsersProps {
  items: Id[];
  sourceId: Id;
  loading: boolean;

  onFetch(): void;

  onReset(): void;
}

export class Users extends React.PureComponent<UsersProps> {

  componentDidMount() {
    this.props.onFetch();
  }

  componentWillUnmount() {
    this.props.onReset();
  }

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderItem = (item) => (
    <UserConnect
      key={item}
      id={item}
      sourceId={this.props.sourceId}
    >
      <RoleConnect
        sourceId={this.props.sourceId}
        userId={item}
      />
    </UserConnect>
  );

  render() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return (
      <s.Root>{this.props.items.map(this.renderItem)}</s.Root>
    );
  }

}

export default Users;
