import { StateBuilder as SearchResultStateBuilder } from './searchPackages/state';
import { StateBuilder as PackagesStateBuilder } from './packages/state';
import { StateBuilder as DocumentsBuilder } from './documents/state';
import { StateBuilder as LayoutBuilder } from '../header/redux/state';
import { StateBuilder as UsersBuilder } from './users/state';

export enum Source {
  package = 'package',
}

export enum TagDTO {
  pos_high = 'highPositive',
  positive = 'positive',
  neutral = 'neutral',
  negative = 'negative',
  neg_high = 'highNegative',
  not_suit = 'notSuit',
  empty = 'empty',
}

export enum Tag {
  highPositive = 'pos_high',
  positive = 'positive',
  neutral = 'neutral',
  negative = 'negative',
  highNegative = 'neg_high',
  notSuit = 'not_suit',
  empty = 'empty',
}

export class StateBuilder {

  static createState() {
    return {
      searchResult: SearchResultStateBuilder.createState(),
      packages: PackagesStateBuilder.createState(),
      documents: DocumentsBuilder.createState(),
      layout: LayoutBuilder.createState(),
      users: UsersBuilder.createState(),
      presets: {
        tags: {},
      },
    };
  }

}

export default StateBuilder;
