import { ReducerBuilder } from '@redux/reducer.builder';

import { reducerBuilder as documentsSettingsReducer } from '../header/settingsPopover/redux/reducer';

import { changePreviewDocument } from '../preview/redux/changePreviewDocument';
import { fetchDocument } from '../preview/redux/fetchDocument';
import { fetchAttributes } from '../preview/redux/fetchAttributes';
import { fetchMarkers } from '../preview/redux/fetchMarkers';
import { saveMarkers } from '../preview/redux/saveMarkers';
import { saveCommentary } from '../preview/redux/saveCommentary';
import { markIsVisited } from '../preview/redux/markIsVisited';
import { markIsVisitedFromLink } from './markIsVisitedFromLink';

import { resetDocuments } from './resetDocuments';
import { fetchDocuments } from './fetchDocuments';
import { fetchDocumentsPackageCount } from './fetchDocumentsPackageCount';
import { fetchSettings } from './fetchSettings';
import { toggleItemSelect } from './toggleItemSelect';
import { toggleSelectionRule } from './toggleSelectionRule';
import { increasePackageCount } from './increasePackageCount';
import { decreasePackageCount } from './decreasePackageCount';
import { changePageNumber } from './changePageNumber';
import { clearPages } from './clearPages';

import {
  loadingStart,
  loadingEnd,
} from './loading';

import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

export const reducerBuilder = new ReducerBuilder([
  changePageNumber,
  changePreviewDocument,
  decreasePackageCount,
  fetchAttributes,
  fetchMarkers,
  saveMarkers,
  fetchDocument,
  fetchDocuments,
  fetchDocumentsPackageCount,
  fetchSettings,
  increasePackageCount,
  loadingEnd,
  loadingStart,
  pageLoadingEnd,
  pageLoadingStart,
  resetDocuments,
  saveCommentary,
  markIsVisited,
  markIsVisitedFromLink,
  toggleItemSelect,
  toggleSelectionRule,
  clearPages,
]);

reducerBuilder.merge(documentsSettingsReducer);

export default reducerBuilder;
