import React from 'react';

import { plural } from '@components/plural';
import { separator } from '@components/separator';

import s from './header.style';

import { Total } from '../redux/state';
import { CreateSubscriptionConnect as CreateSubscription } from './createSubscription/createSubscription.connect';
import { SettingsPopover } from './settingsPopover/settingsPopover';
import { AddPackageConnect } from './addPackage/addPackage.connect';
import { SortMenuConnect } from './sortMenu/sortMenu.connect';

export interface HeaderProps {
  totalDocuments: Total;
  invokeOnSort?(): void;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    const endings = ['документ', 'документа', 'документов'];
    const pluralize = plural(this.props.totalDocuments, endings);
    const totalDocuments = separator(this.props.totalDocuments);
    const title = `Найдено ${totalDocuments} ${pluralize}`;

    return (
      <s.Root>
        <s.TopHeader>
          <s.Title>
            {title}
          </s.Title>
          <s.Actions>
            <s.ActionIcon>
              <CreateSubscription />
            </s.ActionIcon>
            <s.ActionIcon>
              <AddPackageConnect />
            </s.ActionIcon>
            <s.OptionsKebab>
              <SettingsPopover />
            </s.OptionsKebab>
          </s.Actions>
        </s.TopHeader>
        <SortMenuConnect onClick={this.props.invokeOnSort} />
      </s.Root>

    );
  }

}

export default Header;
