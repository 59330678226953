import {
  AccessId,
  StateBuilder,
} from './state';

export type ActionData = {
  access: AccessId;
};

export interface Action {
  type: string;
  data: ActionData;
}

export const changeDefaultAccessReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state.users);
  newState.setDefaultAccess(action.data.access);

  return {
    ...state,
    users: newState.getState(),
  };
};

export default changeDefaultAccessReduce;
