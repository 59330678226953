import React from 'react';

import { RoutePath } from '@router/path';
import { AuthRouteConnect } from '@router/authRoute.connect';
import { SearchResultConnect } from '@pages/searchResult/searchResult.connect';

export const RouteSearchResult = (
  <AuthRouteConnect
    path={[RoutePath.documents, RoutePath.law, RoutePath.analysis, RoutePath.courts]}
    component={SearchResultConnect}
    exact
  />
);

export default RouteSearchResult;
