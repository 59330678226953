import { connect } from 'react-redux';

import { fetchUsersThunk } from '../../redux/users/fetchUsers.thunk';
import { resetToInitial } from '../../redux/users/resetToInitial';

import { Users } from './users';

const mapStateToProps = (state) => ({
  items: state.packages.users.itemsList,
  loading: state.packages.users.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch(query = '') {
    fetchUsersThunk(dispatch, { sourceId: ownProps.sourceId, query });
  },
  onReset() {
    dispatch(resetToInitial.createAction());
  },
});

export const UsersConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);

export default UsersConnect;
