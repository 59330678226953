import React from 'react';

import { Loader } from '@components/loader';

import { AccessConnect } from './access.connect';
import { DefaultAccessConnect } from './defaultAccess.connect';
import { UserConnect } from './user.connect';
import { SearchUsers } from './searchUsers';

import { DefaultAccess } from './defaultAccess';

import s from './users.style';

export type Id = number;

interface UsersProps {
  items: Id[];
  sourceId: Id;
  loading: boolean;

  onFetch(query?: string): void;

  onReset(): void;
}

export class Users extends React.PureComponent<UsersProps> {

  componentDidMount() {
    this.props.onFetch();
  }

  componentWillUnmount() {
    this.props.onReset();
  }

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderItem = (item) => (
    <UserConnect
      key={item}
      id={item}
      sourceId={this.props.sourceId}
    >
      <AccessConnect
        sourceId={this.props.sourceId}
        userId={item}
      />
    </UserConnect>
  );

  renderSearch() {
    return (
      <s.Search>
        <SearchUsers
          onSearch={this.props.onFetch}
        />
      </s.Search>
    );
  }

  renderDefault() {
    return (
      <DefaultAccess>
        <DefaultAccessConnect
          sourceId={this.props.sourceId}
        />
      </DefaultAccess>
    );
  }

  renderUsers() {
    if (!this.props.items.length) {
      return null;
    }

    return (
      <s.Users>
        {this.props.items.map(this.renderItem)}
      </s.Users>
    );
  }

  renderContent() {
    if (this.props.loading) {
      return this.renderLoader();
    }

    return (
      <>
        {this.renderDefault()}
        {this.renderUsers()}
      </>
    );
  }

  render() {

    return (
      <s.Root>
        {this.renderSearch()}
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default Users;
