import { SortListItem } from '@layouts/documents/redux/state';
import { selectDocumentsSortReduce } from './selectDocumentsSort.reduce';

const actionType = 'DOCUMENTS/SELECT_SORT';

const createAction = (data: SortListItem) => ({
  type: actionType,
  data,
});

export const selectDocumentsSort = {
  actionType,
  createAction,
  reduce: selectDocumentsSortReduce,
};

export default selectDocumentsSort;
