import { connect } from 'react-redux';

import { RoleId } from '@pages/regulation/redux/users/state';

import { SelectBlock } from '@layouts/shareMenu/selectBlock';

import { changeRoleThunk } from '../../redux/users/changeRole.thunk';

export enum Options {
  owner = 'Владелец',
  visitor = 'Наблюдатель',
  editor = 'Редактор',
  none = 'Доступ запрещен',
}

const options = [
  {
    id: 'owner',
    title: Options.owner,
  },
  {
    id: 'editor',
    title: Options.editor,
  },
  {
    id: 'visitor',
    title: Options.visitor,
  },
  {
    id: 'none',
    title: Options.none,
  },
];

const mapStateToProps = (state, ownProps) => {
  const list = [...options];
  const { itemsMap } = state.regulation.users;

  const user = itemsMap.get(ownProps.userId);
  const disabled = user.role === list[0].id;

  if (!disabled) {
    list.shift();
  }

  const activeItem = list.find((role) => role.id === user.role);

  return {
    list,
    disabled,
    activeItem,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectOption(optionId: RoleId) {
    const data = {
      sourceId: ownProps.sourceId,
      userId: ownProps.userId,
      roleId: optionId,
    };

    changeRoleThunk(dispatch, data);
  },
});

export const RoleConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectBlock);

export default RoleConnect;
