import { Dispatch } from 'redux';

import { changeRole } from './changeRole';
import { httpChangeRole } from './changeRole.http';

import { Id } from './state';

interface Params {
  sourceId: Id;
  userId: Id;
  roleId: string;
}

export class ChangeRoleThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    try {
      await httpChangeRole(params);
      this.dispatchChangeRole(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchChangeRole(params) {
    const data = {
      userId: params.userId,
      roleId: params.roleId,
    };

    this.dispatch(changeRole.createAction(data));
  }

}

export function changeRoleThunk(dispatch, params) {
  const thunk = new ChangeRoleThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  thunk.invoke(params);
}

export default ChangeRoleThunk;
