import React, { useState } from 'react';

import { IconButton } from '@components/buttons';

import { ShareMenu } from '@layouts/shareMenu/shareMenu';
import s from '@pages/regulation/header/share/share.style';
import { UsersConnect } from './users.connect';

interface ShareProps {
  id: number;
  shouldRender?: boolean;
  disabled?: boolean;
}

export const Share: React.FC<ShareProps> = ({ disabled = true, shouldRender = true, id = null }) => {

  const [showMenu, setShowMenu] = useState(false);

  const handleButtonClick = () => {
    if (disabled) {
      return;
    }

    setShowMenu(true);
  };

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  if (!shouldRender) {
    return null;
  }

  if (showMenu) {
    return (
      <ShareMenu
        title="Настроить совместный доступ"
        onClose={handleCloseMenu}
      >
        <UsersConnect sourceId={id} />
      </ShareMenu>
    );
  }

  return (
    <s.Root>
      <IconButton
        iconName="users"
        onClick={handleButtonClick}
        disabled={disabled}
      />
    </s.Root>
  );

};

export default Share;
