import React from 'react';
import copy from 'copy-to-clipboard';

import { sendEvent } from '@utils/sendEvents';
import { EventTypes } from '@utils/sendEvent.http';

import {
  SaveButton,
  ResetButton,
} from '@components/buttons';

import s from './requisitesCopy.style';

interface RequisitesCopyProps {
  id: number;
  onClick?: () => void;
  closePopover: () => void;
  requisites: string;
}

export class RequisitesCopy extends React.PureComponent<RequisitesCopyProps> {

  static defaultProps = {
    onClick: () => {},
  };

  handleCancel = () => {
    this.props.onClick();
    this.props.closePopover();
  };

  handleCopyRequisites = () => {
    copy(this.props.requisites);
    sendEvent(EventTypes.documentCopyDetails, { id: this.props.id });
    this.props.onClick();
    this.props.closePopover();
  };

  renderHeader() {
    return (
      <s.Header>
        Реквизиты документа
      </s.Header>
    );
  }

  renderRequisites() {
    return (
      <s.Requisites>
        {this.props.requisites}
      </s.Requisites>
    );
  }

  renderAnnotation() {
    return (
      <s.Annotation>
        Данные реквизиты будут скопированы в буфер обмена для последующего включения в текст документа
      </s.Annotation>
    );
  }

  renderResetButton() {
    return (
      <ResetButton
        onClick={this.handleCancel}
      />
    );
  }

  renderSaveButton() {
    const title = 'Копировать';

    return (
      <SaveButton
        onClick={this.handleCopyRequisites}
        title={title}
      />
    );
  }

  render() {
    return (
      <s.Container>
        {this.renderHeader()}
        {this.renderRequisites()}
        {this.renderAnnotation()}
        <s.Actions>
          {this.renderResetButton()}
          {this.renderSaveButton()}
        </s.Actions>
      </s.Container>
    );
  }

}

export default RequisitesCopy;
