import React from 'react';

import { Select } from '@pages/search/form/components/select/select';

type Option = {
  id: string;
  title: string;
};

interface OptionsProps {
  list: Option[];
  disabled: boolean;
  activeItem: Option;

  onSelectOption(option: Option['id']): void;
}

export const SelectBlock = (props: OptionsProps) => {

  const handleSelect = (option) => {
    props.onSelectOption(option.id);
  };

  return (
    <Select
      disabled={props.disabled}
      useAnchorWidth
      activeItem={props.activeItem}
      list={props.list}
      onChange={handleSelect}
    />
  );
};

export default SelectBlock;
