import { connect } from 'react-redux';

import { HeaderNotification } from '@components/header/notification/notification';

export const mapStateToProps = (state) => {

  const { endTitle, type } = state.currentUser.licenses.caselook;

  const props = {
    role: type,
    licenseEndTitle: endTitle,
  };

  return props;
};

export const HeaderNotificationConnect = connect(
  mapStateToProps,
)(HeaderNotification);

export default HeaderNotificationConnect;
