import { setLoadingReduce } from './setLoading.reduce';

import type { ActionData } from './setLoading.reduce';

const actionType = 'PACKAGES/USERS/LOADING';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const setLoading = {
  actionType,
  createAction,
  reduce: setLoadingReduce,
};

export default setLoading;
