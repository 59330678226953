import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { Loader } from '@components/loader';
import { OpenDrawer } from '@components/openDrawer';

import { LayoutSettings } from '@pages/searchResult/documents/documents';

import { DocumentId } from './redux/state';

import { HeaderConnect } from './header/header.connect';
import { DocumentPreviewConnect } from './preview/documentPreview.connect';
import { ListConnect as List } from './list/list.connect';
import { TableConnect as Table } from './table/table.connect';
import { PaginationConnect } from './pagination/pagination.connect';

import { Empty } from './empty';

import s from './documents.style';

interface DocumentsProps {
  layout: LayoutSettings;
  isEmpty: boolean;
  loading: boolean;
  pageLoading: boolean;
  onChangeSelectionRule(): void;
}

interface DocumentsState {
  showPreview: boolean;
  documentPreviewId: DocumentId;
}

export class Documents extends React.PureComponent<DocumentsProps, DocumentsState> {

  contentRef: React.RefObject<HTMLDivElement>;

  state = {
    showPreview: false,
    documentPreviewId: null,
  };

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  handleItemClick = (id: DocumentId) => {
    this.setState({
      showPreview: true,
      documentPreviewId: id,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      showPreview: false,
      documentPreviewId: null,
    });
  };

  onAfterPagination = () => {
    this.contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  contentSwitcher() {
    const paginationProps = {
      onAfterPagination: this.onAfterPagination,
    };

    const isListLayout = this.props.layout.viewType === 'list';
    if (isListLayout) {
      return (
        <s.ContentContainer>
          <HeaderConnect invokeOnSort={this.handleCloseDrawer} />
          <s.ItemsContent>
            {this.renderPageLoader()}
            {this.renderList()}
          </s.ItemsContent>
          <PaginationConnect {...paginationProps} />
        </s.ContentContainer>
      );
    }

    return (
      <s.ContentContainer table>
        <HeaderConnect invokeOnSort={this.handleCloseDrawer} />
        <s.ItemsContent>
          {this.renderPageLoader()}
          {this.renderTable()}
        </s.ItemsContent>
        <PaginationConnect {...paginationProps} />
      </s.ContentContainer>
    );
  }

  renderList = () => (
    <List
      documentPreviewId={this.state.documentPreviewId}
      onChangeSelectionRule={this.props.onChangeSelectionRule}
      onItemClick={this.handleItemClick}
    />
  );

  renderTable = () => (
    <Table
      documentPreviewId={this.state.documentPreviewId}
      onChangeSelectionRule={this.props.onChangeSelectionRule}
      onItemClick={this.handleItemClick}
    />
  );

  renderContent() {
    if (this.props.loading) {
      return <Loader />;
    }

    if (this.props.isEmpty) {
      return <Empty />;
    }

    return this.renderLayout();
  }

  renderDocuments = () => (
    <s.Content ref={this.contentRef}>
      {this.contentSwitcher()}
    </s.Content>
  );

  renderLayout() {
    return (
      <>
        {this.renderDocuments()}
        {this.renderDrawer()}
      </>
    );
  }

  renderDrawer = () => {
    if (!this.state.showPreview) {
      return null;
    }

    const documentPreviewId = this.state.documentPreviewId;
    sendAmplitudeData('preview doc');
    Userpilot.track('preview doc');

    return (
      <OpenDrawer
        anchor="right"
        withBackground={false}
        component={(
          <DocumentPreviewConnect
            onClose={this.handleCloseDrawer}
            documentId={documentPreviewId}
          />
        )}
        onClickOutside={this.handleCloseDrawer}
      />
    );
  };

  renderPageLoader() {
    if (!this.props.pageLoading) {
      return null;
    }

    return (<s.PageLoader />);
  }

  render() {
    return (
      <s.Root>
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default Documents;
