import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';
import { fetchDocumentsSort } from './fetchDocumentsSort';
import { selectDocumentsSort } from './selectDocumentsSort';
import { resetDocumentsSortSelect } from './resetDocumentsSortSelect';

export const reducerBuilder = new ReducerBuilder([
  fetchDocumentsSort,
  selectDocumentsSort,
  resetDocumentsSortSelect,
]);

reducerBuilder.setInitialState(StateBuilder.createState());

export default reducerBuilder;
