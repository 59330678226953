import { connect } from 'react-redux';

import { SelectBlock } from '@layouts/shareMenu/selectBlock';

import {
  Access,
  AccessId,
} from '../../redux/users/state';

import { changeDefaultAccessThunk } from '../../redux/users/changeDefaultAccess.thunk';

export enum Options {
  open = 'Доступ открыт',
  closed = 'Доступ закрыт',
}

const options = [
  {
    id: Access.open,
    title: Options.open,
  },
  {
    id: Access.closed,
    title: Options.closed,
  },
];

const mapStateToProps = (state) => {
  const { defaultAccess } = state.packages.users;

  const activeItem = options.find((access) => access.id === defaultAccess);

  return {
    list: options,
    activeItem,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSelectOption(optionId: AccessId) {
    const data = {
      sourceId: ownProps.sourceId,
      access: optionId,
    };

    changeDefaultAccessThunk(dispatch, data);
  },
});

export const DefaultAccessConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectBlock);

export default DefaultAccessConnect;
