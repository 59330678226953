import React from 'react';

import { Avatar } from '@components/avatar';

import s from './user.style';

interface UserProps {
  avatar: string;
  name: string;
  email: string;
  status: string;
}

export const User: React.FC<UserProps> = (props) => (
  <s.Root>
    <Avatar src={props.avatar} />
    <s.Info>
      <s.Name>
        {props.name}
      </s.Name>
      <s.Email>
        {props.email}
      </s.Email>
    </s.Info>
    <s.Status>
      {props.status}
    </s.Status>
    <s.Additions>
      {props.children}
    </s.Additions>
  </s.Root>
);

export default User;
