import { fetchDocumentsSortReduce } from './fetchDocumentsSort.reduce';

import type { ActionData } from './fetchDocumentsSort.reduce';

const actionType = 'DOCUMENTS/FETCH_SORT_SUCCEEDED';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDocumentsSort = {
  actionType,
  createAction,
  reduce: fetchDocumentsSortReduce,
};

export default fetchDocumentsSort;
