import { connect } from 'react-redux';

import { Notifications } from './notifications';

const mapStateToProps = (state) => {
  const { caselook, regulation } = state.currentUser.licenses;

  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    shouldRender: multiLicense || monitoringLicense,
  };
};

export const NotificationsConnect = connect(
  mapStateToProps,
)(Notifications);

export default NotificationsConnect;
