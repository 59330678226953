import { Dispatch } from 'redux';

import { changeDefaultAccess } from './changeDefaultAccess';
import { httpFetchDefaultAccess } from './fetchDefaultAccess.http';

import { Id } from './state';

interface Params {
  sourceId: Id;
}

export class FetchDefaultAccessThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    const response = await this.changeDefaultAccess(params);
    this.dispatchChangeDefaultAccess(response);
  }

  private async changeDefaultAccess(params) {
    try {
      return await httpFetchDefaultAccess(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchChangeDefaultAccess(params) {
    const data = {
      access: params.access,
    };

    this.dispatch(changeDefaultAccess.createAction(data));
  }

}

export function fetchDefaultAccessThunk(dispatch, params) {
  const thunk = new FetchDefaultAccessThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  thunk.invoke(params);
}

export default FetchDefaultAccessThunk;
