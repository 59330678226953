import React from 'react';

import { IconButton } from '@components/buttons';

import { ShareMenu } from '@layouts/shareMenu/shareMenu';

import { Id } from '../../workspace/redux/state';

import { InviteFormConnect } from '../inviteForm.connect';
import { UsersConnect } from './users.connect';

import s from './share.style';

interface ShareProps {
  id: Id;
  shouldRender: boolean;
}

export class Share extends React.PureComponent<ShareProps> {

  state = {
    showMenu: false,
  };

  handleButtonClick = () => {
    this.setState({
      showMenu: true,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      showMenu: false,
    });
  };

  render() {
    if (!this.props.shouldRender) {
      return null;
    }

    if (this.state.showMenu) {
      return (
        <ShareMenu
          title="Настроить совместный доступ"
          onClose={this.handleCloseMenu}
        >
          <InviteFormConnect id={this.props.id} />
          <UsersConnect sourceId={this.props.id} />
        </ShareMenu>
      );
    }

    return (
      <s.Root>
        <IconButton iconName="users" onClick={this.handleButtonClick} />
      </s.Root>
    );
  }

}

export default Share;
