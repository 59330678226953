import { changeDefaultAccessReduce } from './changeDefaultAccess.reduce';

import type { ActionData } from './changeDefaultAccess.reduce';

const actionType = 'PACKAGES/USERS/CHANGE_DEFAULT_ACCESS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const changeDefaultAccess = {
  actionType,
  createAction,
  reduce: changeDefaultAccessReduce,
};

export default changeDefaultAccess;
