import { clearPagesReduce } from './clearPages.reduce';

const actionType = 'SEARCH/RESULT/DOCUMENTS/CLEAR_PAGES';

const createAction = () => ({
  type: actionType,
});

export const clearPages = {
  actionType,
  createAction,
  reduce: clearPagesReduce,
};

export default clearPages;
