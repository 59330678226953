import { Map } from 'immutable';

export type Id = number;
export type Loading = boolean;

export type RoleId = 'owner' | 'visitor' | 'editor' | 'none';

export interface UserDTO {
  user_id: Id;
  name: string;
  email: string;
  avatar: string;
  role: RoleId;
  status: {
    date: string;
    title: string;
  }
}

export interface User {
  id: Id;
  name: string;
  email: string;
  avatar: string;
  role: RoleId;
  status: string;
}

export class StateBuilder {

  private state: {
    itemsList: Id[];
    itemsMap: Map<Id, User>;
    loading: Loading;
  };

  constructor(state) {
    this.state = state;
  }

  static createState() {
    return {
      itemsList: [],
      itemsMap: Map(),
      loading: true,
    };
  }

  public addUser(item: UserDTO) {
    const user = this.buildUser(item);
    this.addToItemsList(user.id);
    this.addToItemsMap(user.id, user);
  }

  public buildUsers(items) {
    items.forEach((item) => {
      this.addUser(item);
    });
  }

  public updateRole(id: Id, roleId: RoleId) {
    const user = this.state.itemsMap.get(id);
    const newUser = {
      ...user,
      role: roleId,
    };

    const itemsMap = this.state.itemsMap.set(id, newUser);

    this.state = {
      ...this.state,
      itemsMap,
    };
  }

  public setLoading(loading: Loading) {
    this.state = {
      ...this.state,
      loading,
    };
  }

  public getState() {
    return this.state;
  }

  private buildUser(item: UserDTO): User {
    return {
      id: item.user_id,
      name: item.name,
      email: item.email,
      avatar: item.avatar,
      role: item.role,
      status: `${item.status.title} ${item.status.date}`,
    };
  }

  private addToItemsList(id) {
    this.state.itemsList.push(id);
  }

  private addToItemsMap(id, user) {
    this.state.itemsMap = this.state.itemsMap.set(id, user);
  }

}

export default StateBuilder;
