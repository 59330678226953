import { connect } from 'react-redux';

import { User } from '@layouts/shareMenu/user';

const mapStateToProps = (state, ownProps) => {
  const { itemsMap } = state.regulation.users;

  const item = itemsMap.get(ownProps.id);

  return {
    avatar: item.avatar,
    name: item.name,
    email: item.email,
    status: item.status,
  };
};

export const UserConnect = connect(
  mapStateToProps,
)(User);

export default UserConnect;
