import { styled } from '@components/styled';

interface Props {
  theme: Record<any, any>;
}

const style: any = {};

style.Root = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border: 1px solid ${(props: Props) => props.theme.colors.borderColor};
  margin-bottom: 16px;
`;

style.Icon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  background: ${(props) => props.theme.colors.defaultButton};

  path {
    transform: translateX(0px) translateY(-3px) scale(0.33)
  }
`;

export default style;
