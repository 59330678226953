import React from 'react';
import { theme } from '@components/styled';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from './emptyAvatar.style';

export type AvatarProps = {
  color?: string;
  size?: number
};

export const EmptyAvatar = ({ size = 32, color = theme.colors.borderColor }: AvatarProps) => (
  <s.Root>
    <Icon
      path={iconsPath.get('avatar')}
      size={size}
      viewBoxSize={size}
      color={color}
    />
  </s.Root>
);

export default EmptyAvatar;
