import { RESTPath } from '@router/path.rest';
import http from '@http';

import type { SortListItem } from '@layouts/documents/redux/state';

interface RequestParams {
  sourceId: number,
  pageNumber: number,
  searchId?: number,
  sort?: SortListItem
}

export function httpFetchSubscriptionDocuments(requestParams: RequestParams) {
  const request = http.get({
    url: RESTPath.subscriptions.getDocuments(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchSubscriptionDocuments;
