import { styled } from '@components/styled';

interface Props {
  theme: Record<any, any>;
}

const style: any = {};

style.Root = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor}
`;

style.Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  line-height: 16px;
  flex-shrink: 0;
  margin-left: 32px;
`;

style.Name = styled.div`
  font-size: 13px;
`;

style.Email = styled.div`
  font-size: 11px;
  color: ${(props: Props) => props.theme.colors.stone};
`;

style.Status = styled.div`
  font-size: 13px;
  width: 288px;
  color: ${(props: Props) => props.theme.colors.silver};
`;

style.Additions = styled.div`
  margin-left: 16px;
  width: 200px;
  height: 56px;
  flex-shrink: 0;

  .cl-arrow {
    width: 24px;
  }
`;

export default style;
