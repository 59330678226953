import { Dispatch } from 'redux';

import { changeDefaultAccess } from './changeDefaultAccess';
import { httpChangeDefaultAccess } from './changeDefaultAccess.http';

import {
  Id,
  Access,
} from './state';

interface Params {
  sourceId: Id;
  access: Access;
}

export class ChangeDefaultAccessThunk {

  getState;

  private readonly dispatch: Dispatch;

  private params: Params = {
    sourceId: null,
    access: null,
  };

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    this.params = params;
    this.dispatchChangeDefaultAccess();
    await this.changeDefaultAccess();
  }

  private async changeDefaultAccess() {
    try {
      await httpChangeDefaultAccess(this.params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchChangeDefaultAccess() {
    const data = {
      access: this.params.access,
    };

    this.dispatch(changeDefaultAccess.createAction(data));
  }

}

export function changeDefaultAccessThunk(dispatch, params) {
  const thunk = new ChangeDefaultAccessThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  thunk.invoke(params);
}

export default ChangeDefaultAccessThunk;
