import { Dispatch } from 'redux';

import { resetToInitial } from './resetToInitial';
import { httpFetchUsers } from './fetchUsers.http';
import { fetchUsers } from './fetchUsers';
import { changeDefaultAccess } from './changeDefaultAccess';
import { fetchDefaultAccessThunk } from './fetchDefaultAccess.thunk';

import type {
  Id,
  AccessId,
} from './state';

interface Params {
  sourceId: Id;
  query?: string;
}

export class FetchUsersThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    this.dispatch(resetToInitial.createAction());
    await fetchDefaultAccessThunk(this.dispatch, { sourceId: params.sourceId });
    const response = await this.fetchUsers(params);
    this.dispatchFetchSucceed(response.result);
  }

  private async fetchUsers(params) {
    try {
      return await httpFetchUsers(params.sourceId, params.query);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchFetchSucceed(result) {
    this.dispatch(fetchUsers.createAction(result));
  }

  private dispatchChangeDefaultAccess(access: AccessId) {
    this.dispatch(changeDefaultAccess.createAction({ access }));
  }

}

export async function fetchUsersThunk(dispatch, params) {
  const thunk = new FetchUsersThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  await thunk.invoke(params);
}

export default FetchUsersThunk;
