import { connect } from 'react-redux';

import { AddFolder } from '@components/addFolder/addFolder';

import { createFolderAddProjectThunk } from '../../../sidebar/folders/redux/createFolderAddProject.thunk';
import { addProjectToFolderThunk } from '../../../redux/searchFolders/addProjectToFolder.thunk';
import { fetchFoldersForProjectThunk } from '../../../redux/searchFolders/fetchFoldersForProject.thunk';
import { resetToInitial } from '../../../redux/searchFolders/resetToInitial';

const mapStateToProps = (state, ownProps) => {
  const folder = state.regulation.projects.projects.itemsMap.get(ownProps.id);
  if (!folder) {
    return {
      count: 0,
      items: [],
    };
  }

  let disabled = false;
  const { folders } = state.regulation;
  const item = folders.folders.itemsMap.get(folders.activeItemId);
  const isLocalVisitor = item && item.role === 'visitor';
  const isGlobalVisitor = state.currentUser.licenses.regulation.tarif === 'visitor';
  if (isLocalVisitor || isGlobalVisitor) {
    disabled = true;
  }

  const folderCount = folder.folderCount;
  const count = folderCount > 99 ?
    '99+' :
    folderCount;

  const items = state.regulation.searchResult;

  return {
    disabled,
    count,
    items,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    const projectId = ownProps.id;
    fetchFoldersForProjectThunk(dispatch, { projectId });
  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
  },
  onItemCreate(query: string) {
    const searchQuery = query.trim();
    const projectId = ownProps.id;

    const params = {
      name: searchQuery,
      projectId,
      from: ownProps.from,
    };

    createFolderAddProjectThunk(dispatch, params);
  },
  onItemToggle(folderId: number) {
    const projectId = ownProps.id;
    const params = {
      projectId,
      folderId,
      from: ownProps.from,
    };

    addProjectToFolderThunk(dispatch, params);
  },
});

export const AddFolderConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddFolder);

export default AddFolderConnect;
