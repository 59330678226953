import React from 'react';

import { SortMenuConnect } from './sortMenu/sortMenu.connect';
import { AddPackageConnect } from './addPackage/addPackage.connect';
import { SettingsPopover } from './settingsPopover/settingsPopover';

import s from './header.style';

export interface HeaderProps {
  title: string;
  isReal: boolean;
  invokeOnSort?(): void;
}

export class Header extends React.PureComponent<HeaderProps> {

  renderAddToPackage() {
    if (!this.props.isReal) {
      return null;
    }

    return (
      <s.ActionIcon>
        <AddPackageConnect />
      </s.ActionIcon>
    );
  }

  render() {
    return (
      <s.Root>
        <s.TopHeader>
          <s.Title>
            {this.props.title}
          </s.Title>
          <s.Actions>
            {this.renderAddToPackage()}
            <s.OptionsKebab>
              <SettingsPopover />
            </s.OptionsKebab>
          </s.Actions>
        </s.TopHeader>
        <SortMenuConnect onClick={this.props.invokeOnSort} />
      </s.Root>
    );
  }

}

export default Header;
