import { Dispatch } from 'redux';

import { socket } from '@utils/utils';

import type { SortListItem } from '@layouts/documents/redux/state';
import { getActiveItem } from '@layouts/documents/utils';
import { selectDocumentsSort } from '@layouts/documents/redux/selectDocumentsSort';

import { clearPages } from './clearPages';
import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

import { httpFetchSubscriptionDocuments } from './fetchDocuments.http';

interface Params {
  id?: number,
  pageNumber?: number,
  sortData?: SortListItem
}

interface RequestParams {
  sourceId: number,
  pageNumber: number,
  searchId?: number,
  sort?: SortListItem
}

export class FetchDocumentsThunk {

  getState;

  private readonly dispatch: Dispatch;

  private sourceId: number;

  private pageNumber: number;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.sourceId = null;
    this.pageNumber = null;
  }

  public async invoke(params: Params) {
    const state = this.getState().subscriptions;
    this.sourceId = params.id || state.subscriptions.activeItemId;
    this.pageNumber = params.pageNumber || state.documents.currentPageNumber;

    this.dispatch(pageLoadingStart.createAction());

    if (params.sortData) {
      await this.changeSort(params.sortData);

      return;
    }

    const hasPage = state.documents.pages[this.pageNumber];

    if (hasPage) {
      this.dispatchChangePageNumber();

      return;
    }

    await this.getNewPage();
  }

  private async getNewPage() {
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      searchId: null,
      sort: getActiveItem<SortListItem>(this.getState().documents.documentsSort),
    };

    const response = await this.fetchDocuments(requestParams);
    const channelId = response.channel_id;

    if (!channelId) {
      this.dispatchGetDocumentsSucceed(response);
      this.dispatchChangePageNumber();

      return;
    }

    await this.getThruSocket(channelId, requestParams);

  }

  private async changeSort(sort) {
    this.dispatch(selectDocumentsSort.createAction(sort));
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      sort,
    };

    const response = await this.fetchDocuments(requestParams);
    this.dispatch(clearPages.createAction());
    this.dispatchGetDocumentsSucceed(response);
    this.dispatchChangePageNumber();
  }

  private async fetchDocuments(requestParams: RequestParams) {
    try {
      return await httpFetchSubscriptionDocuments(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async getThruSocket(channelId, requestParams) {
    const userSocket = socket.getSocket();

    if (!socket.getChannel(channelId)) {
      socket.setChannel(channelId);
      await userSocket.emit('enter', channelId);
    }

    await userSocket.once(channelId, async (message) => {
      if (message === 'done_process') {
        const response = await this.fetchDocuments(requestParams);

        this.dispatchGetDocumentsSucceed(response);
        this.dispatchChangePageNumber();
      }
    });
  }

  private dispatchGetDocumentsSucceed(result) {
    const actionData = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      result,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber() {
    const actionData = {
      pageNumber: this.pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchDocumentsThunk(dispatch, params: Params) {
  const thunk = new FetchDocumentsThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  await thunk.invoke(params);
}

export default fetchDocumentsThunk;
