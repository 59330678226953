export const resetDocumentsSortSelectReduce = (state) => {
  if (!state) {
    return state;
  }

  const documentsSort = state.documentsSort.map((item) => ({
    ...item,
    isActive: item.isDefault,
  }));

  return {
    ...state,
    documentsSort,
  };
};

export default resetDocumentsSortSelectReduce;
