import { StateBuilder } from './state';
import type { ResponseSortItem } from './state';

export type ActionData = {
  documentsSort: ResponseSortItem[],
};

interface Action {
  type: string;
  data: ActionData;
}

export const fetchDocumentsSortReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.createState(state);

  newState.setDocumentsSort(action.data.documentsSort);

  return newState.getState();
};

export default fetchDocumentsSortReduce;
