export type SortDirection = 'asc' | 'desc';

export interface SortListItem {
  type: string;
  sortDirection: SortDirection;
  isDefault: boolean;
  title: string;
  isActive: boolean;
}

export type ResponseSortItem = {
  type: string;
  sortDirection: SortDirection;
  isDefault: boolean;
  title: string;
};

interface State {
  documentsSort: SortListItem[],
}

export class StateBuilder {

  documentsSort: SortListItem[] = [];

  static createState(): State {
    return {
      documentsSort: [],
    };
  }

  public createState(state: State) {
    this.documentsSort = [...state.documentsSort];
  }

  public setDocumentsSort(items: ResponseSortItem[]) {
    this.documentsSort = items.map((item) => {

      const arrow = item.sortDirection === 'asc' ? '\u2191' : '\u2193';
      const title = `${item.title} ${arrow}`;

      return {
        ...item,
        title,
        isActive: item.isDefault,
      };
    });
  }

  public getState() {
    return {
      documentsSort: this.documentsSort,
    };
  }

}

export default StateBuilder;
