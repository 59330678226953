import { styled } from '@components/styled';

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  border-bottom: none;
`;

style.Loader = styled.div`
  height: 100%;
  position: relative;
  margin-top: 100px;
`;

style.Search = styled.div`
  margin-bottom: 16px;

  > div {
    height: 49px;
    border: 1px solid ${(props) => props.theme.colors.borderColor};
    padding: 9px 0 9px 28px;

    > div {
      top: 9px;
    }
  }
`;

style.Users = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderColor};

  :last-child {
    border-bottom: none;
  }
`;

export default style;
