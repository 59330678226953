import { connect } from 'react-redux';

import { FolderType } from './redux/state';

import { selectFolderThunk } from './redux/selectFolder.thunk';

import { BaseFolders } from './baseFolders';

const mapStateToProps = (state) => {
  const items = [];

  const {
    underControl: {
      id,
      title,
      count,
    },
    activeItemId,
  } = state.regulation.folders;

  const active = !state.regulation.smartFolders.activeItemId && !activeItemId;

  if (state.currentUser.licenses.regulation.tarif !== 'visitor') {
    items.push({ id: '', title: 'Все проекты', count: 0, active });
  }

  items.push({ id, title, count, active: activeItemId === FolderType.underControl });
  items.push({ id: FolderType.calendar, title: 'Календарь событий', active: activeItemId === FolderType.calendar });
  items.push({ id: FolderType.comments,
    title: 'Комментарии к проектам',
    active: activeItemId === FolderType.comments });

  return {
    items,
    activeItemId,
  };

};

const mapDispatchToProps = (dispatch) => ({
  onSelectItem(id) {
    selectFolderThunk(dispatch, { id });
  },
});

export const BaseFoldersConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BaseFolders);

export default BaseFoldersConnect;
