import { connect } from 'react-redux';

import { buildHistoryItem } from '@utils/utils';

import { Header } from './header';
import { FolderType } from '../sidebar/folders/redux/state';

function getTitle(folders, filters) {
  const activeFolderId = folders.activeItemId;
  const activeFilterId = filters.activeItemId;

  if (activeFilterId) {
    return filters.itemsMap.get(activeFilterId).title;
  }

  if (!activeFolderId) {
    return 'Все проекты';
  }

  if (activeFolderId === FolderType.underControl) {
    return 'Проекты на контроле';
  }

  if (activeFolderId === FolderType.calendar) {
    return 'Календарь событий';
  }

  if (activeFolderId === FolderType.comments) {
    return 'Комментарии к проектам';
  }

  const folder = folders.folders.itemsMap.get(activeFolderId);

  if (!folder) {
    return null;
  }

  return folders.folders.itemsMap.get(activeFolderId).getTitle();
}

const mapStateToProps = (state) => {
  const path = [];
  path.push(buildHistoryItem(1, 'Проекты НПА', false, '#/regulation'));

  const { folders } = state.regulation;

  if (folders.foldersLoading) {
    path.push(buildHistoryItem(2, null, true));
  } else {
    const title = getTitle(folders, state.regulation.smartFolders);
    const hasTitle = !folders.foldersLoading && !!title;
    if (hasTitle) {
      path.push(buildHistoryItem(2, title, false));
    }
  }

  const { caselook, regulation } = state.currentUser.licenses;
  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;

  const multiLicense = caselookLicense && monitoringLicense;

  return {
    path,
    shouldRenderFilters: multiLicense || monitoringLicense,
  };
};

export const HeaderConnect = connect(
  mapStateToProps,
)(Header);

export default HeaderConnect;
