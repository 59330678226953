import React from 'react';

import {
  MenuButton,
  Breadcrumbs,
} from '@components/header';

import { Notifications } from '@layouts/notifications/components/notifications';

import { FiltersSaveConnect } from './components/filtersSave.connect';

import { SettingsConnect } from './settings.connect';
import { ShareConnect } from './share/share.connect';

import s from './header.style';

interface Path {
  id: number;
  title: string;
  route: string;
  loading: boolean;
}

interface HeaderProps {
  path: Path[];
  shouldRenderFilters: boolean;
}

export class Header extends React.PureComponent<HeaderProps> {

  renderActions() {
    return (
      <s.Actions>
        {this.renderFilters()}
        <s.Action>
          <ShareConnect />
        </s.Action>
        <s.Action>
          <Notifications />
        </s.Action>
        <s.Action>
          <SettingsConnect />
        </s.Action>
      </s.Actions>
    );
  }

  renderFilters() {
    if (!this.props.shouldRenderFilters) {
      return null;
    }

    return <FiltersSaveConnect />;
  }

  render() {
    return (
      <s.Root>
        <s.Menu>
          <MenuButton />
        </s.Menu>
        <s.Breadcrumbs>
          <Breadcrumbs breadcrumbs={this.props.path} />
        </s.Breadcrumbs>
        {this.renderActions()}
      </s.Root>
    );
  }

}

export default Header;
