export enum UserRoleType {
  virtual = 'virtual',
  guest = 'guest',
  trial = 'trial',
  admin = 'admin',
  sales = 'sales',
  user = 'user',
  teamlead = 'teamlead',
  support = 'support',

}

interface ConfirmedParam {
  value: string;
  confirmed: boolean;
}

export type License = {
  tarif: string;
  isActive: boolean;
  endDate: string;
  endDays: number;
  isPaidUntil: string;
  endTitle: string;
  isExpired: boolean;
  type: string;
};

export interface UserStateI {
  id: number;
  role: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  phone: ConfirmedParam;
  email: ConfirmedParam;
  company: string;
  position: string;
  avatar: string;
  logo: string;
  accessTo: string[];
  licenses: {
    caselook: License;
    regulation: License;
  };
}

type LicenseDTO = {
  tarif: string;
  is_active: boolean;
  end_date: string;
  days_count_before_end: number;
  is_paid_until: string;
  end_title: string;
  type: string;
};

type ProfileDTO = {
  id: number;
  role: string;
  first_name: string;
  last_name: string;
  patronymic: string;
  phone: string;
  is_phone_validated: boolean;
  email: string;
  is_email_validated: boolean;
  company: string;
  position: string;
  avatar: string;
  logo: string;
};

export interface ActionData {
  access_to: string[];
  profile: ProfileDTO;
  licenses: {
    CASELOOK: LicenseDTO;
    REGULATION: LicenseDTO;
  };
  paymentOptions: any;
  paymentStatus: {
    result: {
      isPayment: boolean;
    };
  };
}

export class UserState {

  state = {
    id: null,
    role: UserRoleType.guest,
    firstName: null,
    lastName: null,
    patronymic: null,
    phone: {
      value: null,
      confirmed: false,
    },
    email: {
      value: null,
      confirmed: false,
    },
    company: null,
    position: null,
    avatar: null,
    logo: null,
    accessTo: [],
    licenses: {
      caselook: {
        tarif: null,
        isActive: false,
        endDate: null,
        endDays: null,
        isPaidUntil: null,
        endTitle: null,
        isExpired: false,
        type: null,
      },
      regulation: {
        tarif: null,
        isActive: false,
        endDate: null,
        endDays: null,
        isPaidUntil: null,
        endTitle: null,
        isExpired: false,
        type: null,
      },
    },
    paymentOptions: [],
    paymentInProgress: false,
  };

  public getState(): UserStateI {
    return this.state;
  }

  public buildState(data: ActionData) {
    this.setId(data);
    this.setRole(data);
    this.setFirstName(data);
    this.setLastName(data);
    this.setPatronymic(data);
    this.setPhone(data);
    this.setEmail(data);
    this.setCompany(data);
    this.setPosition(data);
    this.setAvatar(data);
    this.setLogo(data);
    this.setLicenses(data);

    this.setAccessTo(data);

    this.setPaymentOptions(data);
    this.setPaymentInProgress(false);
  }

  setId(data) {
    if (!data.profile.id) {
      return;
    }

    this.state.id = data.profile.id;
  }

  setRole(data) {
    if (!data.profile.role) {
      return;
    }

    this.state.role = data.profile.role;
  }

  setLicenses(data) {
    const caselook = data.licenses.CASELOOK;
    const regulation = data.licenses.REGULATION;

    if (caselook) {
      this.state.licenses.caselook = {
        tarif: caselook.tarif.toLowerCase(),
        isActive: caselook.is_active,
        endDate: this.setEndDate(caselook.end_date),
        endDays: caselook.days_count_before_end,
        isPaidUntil: caselook.is_paid_until,
        endTitle: caselook.end_title,
        isExpired: !caselook.is_active,
        type: caselook.type,
      };
    }

    if (regulation) {
      this.state.licenses.regulation = {
        tarif: regulation.tarif.toLowerCase(),
        isActive: regulation.is_active,
        endDate: this.setEndDate(regulation.end_date),
        endDays: regulation.days_count_before_end,
        isPaidUntil: regulation.is_paid_until,
        endTitle: regulation.end_title,
        isExpired: !regulation.is_active,
        type: regulation.type,
      };
    }

  }

  setAccessTo(data) {
    if (!data.access_to) {
      return;
    }

    this.state.accessTo = data.access_to;
  }

  setFirstName(data) {
    if (!data.profile.first_name) {
      return;
    }

    this.state.firstName = data.profile.first_name;
  }

  setLastName(data) {
    if (!data.profile.last_name) {
      return;
    }

    this.state.lastName = data.profile.last_name;
  }

  setPatronymic(data) {
    if (!data.profile.patronymic) {
      return;
    }

    this.state.patronymic = data.profile.patronymic;
  }

  setPhone(data) {
    this.state.phone = {
      value: data.profile.phone || this.state.phone.value,
      confirmed: data.profile.is_phone_validated || this.state.phone.confirmed,
    };
  }

  setEmail(data) {
    this.state.email = {
      value: data.profile.email || this.state.email.value,
      confirmed: data.profile.is_email_validated || this.state.email.confirmed,
    };
  }

  setCompany(data) {
    if (!data.profile.company) {
      return;
    }

    this.state.company = data.profile.company;
  }

  setPosition(data) {
    if (!data.profile.position) {
      return;
    }

    this.state.position = data.profile.position;
  }

  setAvatar(data) {
    if (!data.profile.avatar) {
      return;
    }

    this.state.avatar = data.profile.avatar;
  }

  setLogo(data) {
    if (!data.profile.logo) {
      return;
    }

    this.state.logo = data.profile.logo;
  }

  setPaymentOptions(data) {
    if (!data.paymentOptions) {
      return;
    }

    if (!data.paymentOptions.products) {
      return;
    }

    this.state.paymentOptions = data.paymentOptions.products;
  }

  setPaymentInProgress(inProgress: boolean) {
    this.state.paymentInProgress = inProgress;
  }

  private setEndDate(data) {
    if (!data) {
      return null;
    }

    const [day, month, year] = data.split('.');

    return `${year}-${month}-${day}`;
  }

}

export default UserState;
