import { connect } from 'react-redux';

import { resetDocuments } from '@pages/searchResult/documents/redux/resetDocuments';

import { resetChronologyDocuments } from '@layouts/chronology/redux/resetChronologyDocuments';

import { resetToInitial as resetSearch } from '@pages/search/redux/resetToInitial';
import { resetToInitial as resetHistory } from '@pages/search/history/redux/resetToInitial';

import { fetchFiltersFromCbTokenThunk } from './form/redux/fetchFiltersFromCbToken.thunk';
import { resetForm } from './form/redux/resetForm';

import { Search } from './search';

const mapStateToProps = (state, ownProps) => {
  const params = new URLSearchParams(ownProps.location.search);
  const formToken = params.get('cb_guid');
  const noFetchFilters = !!params.get('no_fetch_filters');

  const { caselook, regulation } = state.currentUser.licenses;

  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;
  const multiLicense = caselookLicense && monitoringLicense;

  const renderSmartFilters = caselook.isActive;

  return {
    renderNavigation: !!multiLicense,
    formToken,
    noFetchFilters,
    renderSmartFilters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onResetSearchResult() {
    dispatch(resetDocuments.createAction());
    dispatch(resetChronologyDocuments.createAction());
  },
  onResetSearch() {
    dispatch(resetSearch.createAction());
    dispatch(resetHistory.createAction());
  },
  onResetForm() {
    dispatch(resetForm.createAction());
  },
  onFetchForm(token) {
    fetchFiltersFromCbTokenThunk(dispatch, token);
  },
});

export const SearchConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);

export default SearchConnect;
