import { SortListItem } from '@layouts/documents/redux/state';

interface Action {
  type: string;
  data: SortListItem;
}

export const selectDocumentsSortReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const documentsSort = state.documentsSort.map((item) => {
    const isActive = item.type === action.data.type && item.sortDirection === action.data.sortDirection;

    return {
      ...item,
      isActive,
    };
  });

  return {
    ...state,
    documentsSort,
  };
};

export default selectDocumentsSortReduce;
