import { connect } from 'react-redux';

import { resetDocuments } from '@pages/searchResult/documents/redux/resetDocuments';
import { resetDocumentsSortSelect } from '@layouts/documents/redux/resetDocumentsSortSelect';
import SearchResult from '@pages/searchResult/searchResult';

const mapDispatchToProps = (dispatch) => ({

  onResetToInitial() {
    dispatch(resetDocuments.createAction());
    dispatch(resetDocumentsSortSelect.createAction());
  },
});

export const SearchResultConnect = connect(
  null,
  mapDispatchToProps,
)(SearchResult);

export default SearchResultConnect;
