import React from 'react';

import { CloseButton } from '@components/buttons';

import s from './shareMenu.style';

interface ShareMenuProps {
  title: string;
  onClose: () => void;
}

export const ShareMenu: React.FC<ShareMenuProps> = (props) => (
  <s.Root>
    <s.Header>
      <s.Action>
        <CloseButton
          color="#4183d7"
          onClick={props.onClose}
        />
      </s.Action>
      <s.Title>{props.title}</s.Title>
    </s.Header>
    <s.Workspace>
      <s.WorkspaceContainer>
        {props.children}
      </s.WorkspaceContainer>
    </s.Workspace>
  </s.Root>
);

export default ShareMenu;
