import { connect } from 'react-redux';

import { resetToDefault } from '../../redux/users/resetToDefault';
import { fetchUsersThunk } from '../../redux/users/fetchUsers.thunk';

import { Users } from './users';

const mapStateToProps = (state) => {
  const {
    itemsList,
    loading,
  } = state.regulation.users;

  return {
    items: itemsList,
    loading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetch() {
    fetchUsersThunk(dispatch, { folderId: ownProps.sourceId });
  },
  onReset() {
    dispatch(resetToDefault.createAction());
  },
});

export const UsersConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);

export default UsersConnect;
