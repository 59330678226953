import React from 'react';

import { Loader } from '@components/loader';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';
import { OpenDrawer } from '@components/openDrawer';
import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { NotFound } from '@components/notFound';

import { Header } from '../components/header/header';

import type {
  SearchId,
  DocumentId,
} from '../redux/state';

import { ProcessingStatus } from '../redux/state';

import type { Loading } from './redux/state';

import { HeaderConnect } from './header/header.connect';
import { PaginationConnect } from './pagination/pagination.connect';
import { DocumentPreviewConnect } from './preview/documentPreview.connect';
import { ListConnect as List } from './list/list.connect';
import { TableConnect as Table } from './table/table.connect';

import s from './documents.style';

type RenderType = 'list' | 'table';

export interface LayoutSettings {
  viewType: RenderType;
}

export interface DocumentsProps {
  id: SearchId;
  isEmpty: boolean;
  layout: LayoutSettings;
  items: DocumentId[];
  loading: Loading;
  pageLoading: Loading;
  processingStatus: ProcessingStatus;
  onFetch(): void;
  onFetchSettings(): void;
  onResetPageNumber(): void;
  onBootstrap(): void;
  onChangeSelectionRule(): void;
}

interface State {
  showPreview: boolean;
  documentPreviewId: DocumentId
}

export class Documents extends React.PureComponent<DocumentsProps, State> {

  state = {
    showPreview: false,
    documentPreviewId: null,
  };

  contentRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.contentRef = React.createRef();
  }

  componentDidMount() {
    Userpilot.reload();
    this.props.onFetchSettings();
    this.fetchDocumentsMount();
    this.props.onBootstrap();
  }

  componentDidUpdate(prevProps: DocumentsProps) {
    const isNew = this.props.id !== prevProps.id;
    if (!isNew) {
      return;
    }

    this.props.onFetchSettings();
    this.props.onFetch();
  }

  componentWillUnmount() {
    this.props.onResetPageNumber();
  }

  handleItemClick = (id: DocumentId) => {
    this.setState({
      showPreview: true,
      documentPreviewId: id,
    });
  };

  handleCloseDrawer = () => {
    this.setState({
      showPreview: false,
      documentPreviewId: null,
    });
  };

  onAfterPagination = () => {
    this.contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchDocumentsMount() {
    const isProcessingStatus = this.props.processingStatus === ProcessingStatus.none ||
      this.props.processingStatus === ProcessingStatus.done;

    if (isProcessingStatus) {
      this.props.onFetch();
    }
  }

  contentSwitcher() {
    if (this.props.isEmpty) {
      return <NotFound />;
    }

    const paginationProps = {
      onAfterPagination: this.onAfterPagination,
    };

    const isListLayout = this.props.layout.viewType === 'list';
    if (isListLayout) {
      return (
        <s.ContentContainer>
          <HeaderConnect invokeOnSort={this.handleCloseDrawer} />
          <s.ItemsContent>
            {this.renderPageLoader()}
            {this.renderList()}
          </s.ItemsContent>
          <PaginationConnect {...paginationProps} />
        </s.ContentContainer>
      );
    }

    return (
      <s.ContentContainer table>
        <HeaderConnect invokeOnSort={this.handleCloseDrawer} />
        <s.ItemsContent>
          {this.renderPageLoader()}
          {this.renderTable()}
        </s.ItemsContent>
        <PaginationConnect {...paginationProps} />
      </s.ContentContainer>
    );
  }

  renderList = () => (
    <List
      documentPreviewId={this.state.documentPreviewId}
      onChangeSelectionRule={this.props.onChangeSelectionRule}
      onItemClick={this.handleItemClick}
    />
  );

  renderTable = () => (
    <Table
      documentPreviewId={this.state.documentPreviewId}
      onChangeSelectionRule={this.props.onChangeSelectionRule}
      onItemClick={this.handleItemClick}
    />
  );

  renderContent = () => (
    <s.Content ref={this.contentRef}>
      {this.contentSwitcher()}
    </s.Content>
  );

  renderDrawer = () => {
    if (!this.state.showPreview) {
      return null;
    }

    const documentPreviewId = this.state.documentPreviewId;
    sendAmplitudeData('preview doc');
    Userpilot.track('preview doc');

    return (
      <OpenDrawer
        anchor="right"
        withBackground={false}
        component={(
          <DocumentPreviewConnect
            onClose={this.handleCloseDrawer}
            documentId={documentPreviewId}
          />
        )}
        onClickOutside={this.handleCloseDrawer}
      />
    );
  };

  renderLayout = () => (
    <s.Root>
      <Header sourceId={this.props.id} />
      {this.renderContent()}
      {this.renderDrawer()}
    </s.Root>
  );

  renderPageLoader() {
    if (!this.props.pageLoading) {
      return null;
    }

    return (<s.PageLoader />);
  }

  renderMain() {
    if (this.props.loading) {
      return <Loader />;
    }

    return this.renderLayout();
  }

  render() {
    return (
      <WithHeaderNotification>
        {this.renderMain()}
      </WithHeaderNotification>
    );
  }

}

export default Documents;
