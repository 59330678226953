import React from 'react';

import { AddPackageConnect } from './addPackage/addPackage.connect';
import { SettingsPopover } from './settingsPopover/settingsPopover';

import s from './header.style';
import { SortMenuConnect } from './sortMenu/sortMenu.connect';

export interface HeaderProps {
  title: string;
  invokeOnSort?(): void;
}

export class Header extends React.PureComponent<HeaderProps> {

  render() {
    return (
      <s.Root>
        <s.TopHeader>
          <s.Title>
            {this.props.title}
          </s.Title>
          <s.Actions>
            <s.ActionIcon>
              <AddPackageConnect />
            </s.ActionIcon>
            <s.OptionsKebab>
              <SettingsPopover />
            </s.OptionsKebab>
          </s.Actions>
        </s.TopHeader>
        <SortMenuConnect onClick={this.props.invokeOnSort} />
      </s.Root>
    );
  }

}

export default Header;
