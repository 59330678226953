import {
  DocumentData, httpSendEvent, RequestParams,
} from './sendEvent.http';

export function sendEvent(type: string, data: DocumentData) {
  const requestParams = {
    type,
    ...data,
  } as RequestParams;

  httpSendEvent(requestParams);
}

export default sendEvent;
