import { ReducerBuilder } from '@redux/reducer.builder';

import { changePreviewDocument } from '../preview/redux/changePreviewDocument';
import { fetchDocument } from '../preview/redux/fetchDocument';
import { fetchAttributes } from '../preview/redux/fetchAttributes';
import { fetchMarkers } from '../preview/redux/fetchMarkers';
import { saveMarkers } from '../preview/redux/saveMarkers';
import { saveCommentary } from '../preview/redux/saveCommentary';

import { fetchSettings } from './fetchSettings';
import { fetchDocuments } from './fetchDocuments';
import { fetchDocumentsPackageCount } from './fetchDocumentsPackageCount';
import { toggleItemSelect } from './toggleItemSelect';
import { toggleSelectionRule } from './toggleSelectionRule';
import { increasePackageCount } from './increasePackageCount';
import { decreasePackageCount } from './decreasePackageCount';
import { changePageNumber } from './changePageNumber';
import { resetToInitial } from './resetToInitial';
import { clearPages } from './clearPages';
import {
  pageLoadingStart,
  pageLoadingEnd,
} from './pageLoading';

// eslint-disable-next-line max-len
import { reducerBuilder as documentsSettingsReducer } from '../header/settingsPopover/redux/reducer';

import {
  loadingStart,
  loadingEnd,
} from './loading';

export const reducerBuilder = new ReducerBuilder([
  fetchSettings,
  fetchDocuments,
  fetchDocument,
  fetchDocumentsPackageCount,
  changePreviewDocument,
  fetchAttributes,
  fetchMarkers,
  saveMarkers,
  saveCommentary,
  toggleItemSelect,
  toggleSelectionRule,
  increasePackageCount,
  decreasePackageCount,
  changePageNumber,
  loadingStart,
  loadingEnd,
  pageLoadingStart,
  pageLoadingEnd,
  resetToInitial,
  clearPages,
]);

reducerBuilder.merge(documentsSettingsReducer);

export default reducerBuilder;
