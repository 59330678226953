import React from 'react';

import { SearchField } from '@components/searchField/searchField';
import { debounce } from 'lodash';

interface SearchUsersProps {
  onSearch: (value: string) => void;
}

export class SearchUsers extends React.PureComponent<SearchUsersProps> {

  state = {
    value: '',
  };

  private debouncedUpdateValue = debounce((inputValue) => {
    this.props.onSearch(inputValue);
  }, 300);

  handleChangeValue = (e) => {
    const inputValue = e.target.value;

    this.setState({
      value: inputValue,
    });

    this.debouncedUpdateValue(inputValue);
  };

  render() {
    const placeholder = 'Поиск';

    return (
      <SearchField
        value={this.state.value}
        placeholder={placeholder}
        onChange={this.handleChangeValue}
      />
    );
  }

}

export default SearchUsers;
