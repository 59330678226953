import { connect } from 'react-redux';

import { fetchSearchDocumentsThunk } from '@pages/searchResult/documents/redux/fetchDocuments.thunk';
import { DocumentsSortMenuConnect } from '@layouts/documents/components/documentsSortMenu.connect';
import type { SortListItem } from '@layouts/documents/redux/state';

const mapDispatchToProps = (dispatch) => ({
  onSelect(value: SortListItem) {
    fetchSearchDocumentsThunk(dispatch, { pageNumber: 1, sortData: value });
  },
});

export const SortMenuConnect = connect(
  null,
  mapDispatchToProps,
)(DocumentsSortMenuConnect);

export default SortMenuConnect;
