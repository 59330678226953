import { connect } from 'react-redux';
import { RoutePath } from '@router/path';

import { menuItems as items } from '../menu.items';
import { Navigation } from './navigation';

const mapStateToProps = (state) => {
  const { caselook, regulation } = state.currentUser.licenses;

  const caselookLicense = caselook.isActive || caselook.isExpired;
  const monitoringLicense = regulation.isActive;
  const multiLicense = caselookLicense && monitoringLicense;

  const menuItems = [...items];
  const onlyMonitoring = monitoringLicense && !caselook.isActive;

  if (onlyMonitoring) {
    menuItems[0].link = RoutePath.searchLaw;
  }

  if (multiLicense) {
    return {
      menuItems,
    };
  }

  if (caselookLicense) {
    return {
      menuItems: menuItems.filter((item) => item.id !== 5),
    };
  }

  const validItems = [];

  validItems.push(items[0]);
  validItems.push(items[4]);

  validItems[0].link = RoutePath.searchLaw;

  return {
    menuItems: validItems,
  };
};

export const NavigationConnect = connect(
  mapStateToProps,
)(Navigation);

export default NavigationConnect;
