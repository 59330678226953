import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import 'moment/locale/ru';

import { UserRoleType } from '@pages/userPages/redux/state';
import { plural } from '@components/plural';
import { separator } from '@components/separator';

import { LicenseCassette } from './licenseCassette';

function createItem(title = '', value = '', alert = false) {
  return {
    id: uuid(),
    title,
    value,
    alert,
  };
}

function prepareItems(data) {
  const items = [];
  const stateValue = data.isActive ? 'Активна' : 'Не действует';
  items.push(createItem('Статус', stateValue, !data.isActive));

  const localDate = moment(data.endDate).locale('ru').format('DD MMM YYYY');
  const date = localDate.replace('.', '');

  const dateValue = data.endDate ? date : '—';
  items.push(createItem('Дата окончания', dateValue));

  const endings = ['день', 'дня', 'дней'];
  const daysValue = data.endDays ?
    `${separator(data.endDays)} ${plural(data.endDays, endings)}` :
    '—';

  items.push(createItem('До окончания', daysValue));

  return items;
}

const mapStateToProps = (state) => {
  const { role, licenses } = state.currentUser;
  const shouldRenderProlongControl = role === UserRoleType.user ||
    role === UserRoleType.guest ||
    role === UserRoleType.trial;

  const monitoringLicense = licenses.regulation.isActive;

  const props = {
    caselook: {
      shouldRenderProlongControl,
      paymentInProgress: state.currentUser.paymentInProgress,
      items: prepareItems(licenses.caselook),
    },
    regulation: {
      isActive: monitoringLicense,
      items: prepareItems(licenses.regulation),
    },
  };

  return props;
};

export const LicenseCassetteConnect = connect(
  mapStateToProps,
)(LicenseCassette);

export default LicenseCassetteConnect;
