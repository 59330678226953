import React from 'react';
import { theme } from '@components/styled';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import s from '@layouts/shareMenu/user.style';
import d from './defaultAccess.style';

interface UserProps {
  status?: string;
}

export const DefaultAccess: React.FC<UserProps> = (props) => (
  <d.Root>
    <d.Icon>
      <Icon
        path={iconsPath.get('avatars')}
        color={theme.colors.borderColor}
      />
    </d.Icon>
    <s.Info>
      <s.Name>
        Доступ по умолчанию
      </s.Name>
      <s.Email>
        Для всех пользователей команды
      </s.Email>
    </s.Info>
    <s.Status>
      {props.status}
    </s.Status>
    <s.Additions>
      {props.children}
    </s.Additions>
  </d.Root>
);

export default DefaultAccess;
