import React from 'react';

import { Field } from '@components/field';
import { Field as ProfileField } from '../../components/field';

import s from './userCassette.style';

type Text = string;

interface UserCassetteProps {
  lastName: string;
  firstName: string;
  patronymic: string;
  phone: {
    value: string;
    confirmed: boolean;
  };
  email: {
    value: string;
    confirmed: boolean;
  };
  notificationEmail: {
    email: string;
    confirmed: boolean;
  };
  company: string;
  position: string;

  onSaveFirstname(text: Text): void;

  onSaveLastname(text: Text): void;

  onSavePosition(text: Text): void;
}

interface UserCassetteState {
  firstname: string;
  lastname: string;
  position: string;
}

export class UserCassette extends React.PureComponent<UserCassetteProps, UserCassetteState> {

  state = {
    firstname: this.props.firstName,
    lastname: this.props.lastName,
    position: this.props.position,
  };

  private handleSetLastname = (event) => {
    this.setState({
      lastname: event.target.value,
    });
  };

  private handleBlurLastname = () => {
    if (!this.state.lastname) {
      this.setState({
        lastname: this.props.lastName,
      });

      return;
    }

    const isSameValue = this.state.lastname === this.props.lastName;
    if (isSameValue) {
      return;
    }

    this.props.onSaveLastname(this.state.lastname);
  };

  private handleSetFirstname = (event) => {
    this.setState({
      firstname: event.target.value,
    });
  };

  private handleBlurFirstname = () => {
    if (!this.state.firstname) {
      this.setState({
        firstname: this.props.firstName,
      });

      return;
    }

    const isSameValue = this.state.firstname === this.props.firstName;
    if (isSameValue) {
      return;
    }

    this.props.onSaveFirstname(this.state.firstname);
  };

  private handleSetPosition = (event) => {
    this.setState({
      position: event.target.value,
    });
  };

  private handleBlurPosition = () => {
    const isSameValue = this.state.position === this.props.position;
    if (isSameValue) {
      return;
    }

    this.props.onSavePosition(this.state.position);
  };

  render() {
    return (
      <s.Root>
        <s.Header>
          <s.HeaderTitle>
            Личные данные
          </s.HeaderTitle>
        </s.Header>
        <s.Info>
          <s.InfoRow>
            <s.Field
              hasRightBorder
            >
              <Field
                label="Фамилия"
                value={this.state.lastname}
                onChange={this.handleSetLastname}
                onBlur={this.handleBlurLastname}
              />
            </s.Field>
            <s.Field
              hasRightBorder
            >
              <Field
                label="Имя"
                value={this.state.firstname}
                onChange={this.handleSetFirstname}
                onBlur={this.handleBlurFirstname}
              />
            </s.Field>
            <ProfileField
              width={33.3}
              title="Отчество"
              value={this.props.patronymic}
            />
          </s.InfoRow>
          <s.InfoRow>
            <ProfileField
              width={33.3}
              hasRightBorder
              title="Email для входа в систему"
              value={this.props.email.value}
              hasAction
              confirmed={this.props.email.confirmed}
            />
            <ProfileField
              width={33.3}
              hasRightBorder
              title="Email для уведомлений"
              value={this.props.notificationEmail.email}
            />
            <ProfileField
              width={33.3}
              title="Телефон"
              value={this.props.phone.value}
              hasAction
              confirmed={this.props.phone.confirmed}
            />
          </s.InfoRow>
          <s.InfoRow>
            <ProfileField
              width={33.3}
              hasRightBorder
              title="Наименование компании"
              value={this.props.company}
            />
            <s.Field
              boxWidth={66.7}
            >
              <Field
                label="Должность в компании"
                value={this.state.position}
                onChange={this.handleSetPosition}
                onBlur={this.handleBlurPosition}
              />
            </s.Field>
          </s.InfoRow>
        </s.Info>
      </s.Root>
    );
  }

}

export default UserCassette;
