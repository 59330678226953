import { styled } from '@components/styled';

const style: any = {};

style.Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 320px;
`;

style.Row = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 8px 16px 16px;
  font-size: 17px;
  font-weight: 500;
  height: 57px;
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  position: sticky;
  top: 0;
  z-index: 2;
`;

export default style;
