import {
  styled,
  css,
} from '@components/styled';

interface Props {
  size: number;
  theme: Record<any, any>;
  isHeightDominant: boolean;
}

const style: any = {};

style.Root = styled.div`
  width: ${(props: Props) => props.size}px;
  height: ${(props: Props) => props.size}px;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;

  > img {
    height: 100%;
    width: auto;

    ${(props: Props) => props.isHeightDominant && css`
      height: auto;
      width: 100%;
    `}
  }
`;

export default style;
