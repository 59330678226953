import React from 'react';

import { MenuItem } from '@layouts/documents/components/menuItem';
import type { Item } from '@layouts/documents/components/documentsSortMenu';

import s from './menu.style';

interface Props {
  items: Item[];
  closePopover(): void;
  onSelect(value: Item): void;
}

export const Menu = ({ items, onSelect, closePopover }: Props) => {
  const handleSelect = (item) => {
    onSelect(item);
    closePopover();
  };

  return (
    <s.Root>
      <s.List>
        {items.map((item, index) => (
          <MenuItem
            key={index.toString()} // eslint-disable-line react/no-array-index-key
            item={item}
            onSelect={handleSelect}
          />
        ))}
      </s.List>
    </s.Root>
  );
};

export default Menu;
