import { Userpilot } from 'userpilot';

const setUserpilotUser = (bootstrap) => {
  let name = '';
  if (bootstrap.first_name || bootstrap.last_name) {
    name = `${bootstrap.first_name} ${bootstrap.last_name}`;
  }

  if (!bootstrap.id) {
    Userpilot.anonymous();

    return;
  }

  Userpilot.identify(
    bootstrap.id,
    {
      name,
      email: bootstrap.email,
      created_at: bootstrap.created_at,
    },
  );
};

export const initUserpilot = (bootstrap) => {
  // @ts-ignore
  if (!USERPILOT_TOKEN) {
    return;
  }

  // @ts-ignore
  Userpilot.initialize(USERPILOT_TOKEN);
  setUserpilotUser(bootstrap.profile);
};

export default initUserpilot;

export {
  Userpilot,
};
