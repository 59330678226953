import { connect } from 'react-redux';

import type { SortListItem } from '@layouts/documents/redux/state';
import { getActiveItem } from '@layouts/documents/utils';
import { DocumentsSortMenu } from './documentsSortMenu';

const buildTitle = (list) => {
  const selectedItem = getActiveItem<SortListItem>(list);

  if (!selectedItem) {
    return '';
  }

  return `Сортировать ${selectedItem.title.toLowerCase()}`;
};

const mapStateToProps = (state) => {
  const { documentsSort } = state.documents;
  const title = buildTitle(documentsSort);

  return {
    items: documentsSort,
    title,
  };
};

export const DocumentsSortMenuConnect = connect(
  mapStateToProps,
)(DocumentsSortMenu);

export default DocumentsSortMenuConnect;
