import { Dispatch } from 'redux';

import { changeAccess } from './changeAccess';
import { httpChangeAccess } from './changeAccess.http';

import {
  Id,
  Access,
} from './state';

interface Params {
  sourceId: Id;
  userId: Id;
  access: Access;
}

export class ChangeAccessThunk {

  getState;

  private readonly dispatch: Dispatch;

  private params: Params = {
    sourceId: null,
    userId: null,
    access: null,
  };

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: Params) {
    this.params = params;
    this.dispatchChangeAccess();
    await this.changeAccess();
  }

  private async changeAccess() {
    try {
      await httpChangeAccess(this.params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchChangeAccess() {
    const data = {
      userId: this.params.userId,
      access: this.params.access,
    };

    this.dispatch(changeAccess.createAction(data));
  }

}

export function changeAccessThunk(dispatch, params) {
  const thunk = new ChangeAccessThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  thunk.invoke(params);
}

export default ChangeAccessThunk;
