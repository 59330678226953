import { connect } from 'react-redux';

import { RequisitesCopyPopover } from '@pages/document/components/requisitesCopy/requisitesCopyPopover';

import { buildRequisitesForClipboard } from '@pages/searchResult/documents/utils';

const mapStateToProps = (state) => {
  const { documents } = state.authorPackages;

  return {
    id: documents.previewDocument.documentId,
    requisites: buildRequisitesForClipboard(documents.previewDocument.documentId, {
      ...documents,
      currentPageNumber: documents.previewDocument.currentPageNumber,
    }),
  };
};

export const RequisitesCopyConnect = connect(
  mapStateToProps,
)(RequisitesCopyPopover);

export default RequisitesCopyConnect;
