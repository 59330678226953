import {
  styled, css,
} from '@components/styled';

interface Props {
  theme: any;
  selected?: boolean;
  disable?: boolean;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

style.List = styled.div`
  padding: 8px 0;
  width: 250px;
`;

style.ListItem = styled.div`
  padding: 0 8px 0 16px;
  position: relative;
  height: 40px;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: transparent;
  color: ${(props: Props) => props.theme.colors.defaultText};
  cursor: pointer;

  &:hover {
    background: #f6fafe;
  }

  ${(props: Props) => props.selected && css`
    background: ${props.theme.colors.blue};
    color: ${props.theme.colors.white};

    &:hover {
      background: ${props.theme.colors.blue};
    }
  `}
`;

style.Title = styled.div`
  font: 13px Roboto-Regular;

  ${(props: Props) => props.disable && css`
    color: ${props.theme.colors.silver};
  `}
`;

export default style;
