import {
  DocumentId,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

type StandaloneDocumentData = {
  id: DocumentId;
};

type SimilarDocumentData = {
  refId?: DocumentId;
};

export type DocumentData = StandaloneDocumentData | SimilarDocumentData;

export enum EventTypes {
  documentCopyDetails = 'regular_document:copy_document_details',
  documentOpen = 'regular_document:open',
  similarDocumentOpen = 'similar_document:open',
}

export interface RequestParams {
  type: EventTypes;
  data: DocumentData;
}

export function httpSendEvent(requestParams: RequestParams) {
  const request = http.post({
    url: RESTPath.events,
    data: {
      data: requestParams,
      type: requestParams.type,
    },
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpSendEvent;
