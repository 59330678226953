import { RESTPath } from '@router/path.rest';
import http from '@http';

import { UserRoleType } from '@pages/userPages/redux/state';

export function httpFetchUser() {
  const request = {
    url: RESTPath.user.profile,
  };

  return http.get(request)
    .then(http.handleResponse)
    .then(async (response) => {
      const data = await http.parseResponse(response);
      const userFound = !!data.profile.id;
      if (!userFound && data.profile.role !== UserRoleType.guest) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('User not authenticated');
      }

      return data;
    })
    .catch();
}

export default httpFetchUser;
