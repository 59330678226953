import { connect } from 'react-redux';

import { Source } from '@router/path.rest';
import { notifierManager } from '@components/notification';

import { UserRoleType } from '@pages/userPages/redux/state';
import { getParamsFromUrl } from '@utils/utils';

import sendEvent from '@utils/sendEvents';
import { EventTypes } from '@utils/sendEvent.http';
import { Document } from './document';

import { fetchDocumentThunk } from './redux/fetchDocument.thunk';

import { httpSaveMarkers } from './redux/saveMarkers.http';

import { resetToInitial } from './redux/resetToInitial';

const mapStateToProps = (state, ownProps) => {

  const {
    sourceId,
    documentId,
    chronologyDocumentId,
    similarDocumentId,
  } = getParamsFromUrl(ownProps);

  const props = {
    isAuthorPackage: ownProps.source === Source.authorPackage,
    isReal: state.currentUser.role !== UserRoleType.guest,
    source: ownProps.source,
    sourceId,
    documentId: null,
    parentDocumentId: null,
    isChronologyDocument: !!chronologyDocumentId,
    isSimilarDocument: !!similarDocumentId,
  };

  if (chronologyDocumentId) {
    props.documentId = chronologyDocumentId;
    props.parentDocumentId = documentId;
  } else if (similarDocumentId) {
    props.documentId = similarDocumentId;
    props.parentDocumentId = documentId;
  } else {
    props.documentId = documentId;
  }

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onFetchDocument() {
    const {
      sourceId,
      documentId,
      chronologyDocumentId,
      similarDocumentId,
    } = getParamsFromUrl(ownProps);

    const dispatchParams = {
      source: ownProps.source,
      sourceId,
      documentId: null,
      isChronologyDocument: false,
      isSimilarDocument: false,
      parentDocumentId: null,
    };

    if (chronologyDocumentId) {
      dispatchParams.documentId = chronologyDocumentId;
      dispatchParams.parentDocumentId = documentId;
      dispatchParams.isChronologyDocument = true;
    } else if (similarDocumentId) {
      sendEvent(EventTypes.similarDocumentOpen, { id: similarDocumentId, refId: documentId });
      dispatchParams.documentId = similarDocumentId;
      dispatchParams.parentDocumentId = documentId;
      dispatchParams.isSimilarDocument = true;
    } else {
      dispatchParams.documentId = documentId;
    }

    fetchDocumentThunk(dispatch, dispatchParams);
  },
  onSaveMarkers(entities) {
    const {
      sourceId,
      documentId,
    } = getParamsFromUrl(ownProps);

    const source = ownProps.source === Source.authorPackage ? Source.authorPackage2 : ownProps.source;

    const requestParams = {
      source,
      sourceId,
      documentId,
      data: {
        entities,
      },
    };

    const notificationId = 'SAVE_MARKERS';

    const removeNotification = () => {
      setTimeout(() => {
        notifierManager.remove({ id: notificationId });
      }, 5000);
    };

    try {
      httpSaveMarkers(requestParams);
    } catch (error) {
      if (source === Source.package) {
        notifierManager.fail({ id: notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
        removeNotification();
      }
    }
  },
  onSendEvent() {
    const {
      documentId,
      chronologyDocumentId,
      similarDocumentId,
    } = getParamsFromUrl(ownProps);

    if (!chronologyDocumentId && !similarDocumentId) {
      sendEvent(EventTypes.documentOpen, { id: documentId });
    }
  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
  },
});

export const DocumentConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Document);

export default DocumentConnect;
