import { RESTPath } from '@router/path.rest';
import http from '@http';

import { Id } from '@pages/packages/redux/users/state';

export interface RequestParams {
  sourceId: Id,
}

export function httpFetchDefaultAccess(requestParams: RequestParams): Promise<any> {
  const url = RESTPath.packages.defaultAccess({ sourceId: requestParams.sourceId });

  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchDefaultAccess;
