import { ReducerBuilder } from '@redux/reducer.builder';

import { fetchUsers } from './fetchUsers';
import { setLoading } from './setLoading';
import { changeAccess } from './changeAccess';
import { changeDefaultAccess } from './changeDefaultAccess';
import { resetToInitial } from './resetToInitial';

export const reducerBuilder = new ReducerBuilder([
  fetchUsers,
  setLoading,
  changeAccess,
  changeDefaultAccess,
  resetToInitial,
]);

export default reducerBuilder;
