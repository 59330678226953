import { Dispatch } from 'redux';

import type { SortListItem } from '@layouts/documents/redux/state';
import { notifierManager } from '@components/notification';
import { selectDocumentsSort } from '@layouts/documents/redux/selectDocumentsSort';
import { getActiveItem } from '@layouts/documents/utils';

import { fetchDocuments } from './fetchDocuments';
import { changePageNumber } from './changePageNumber';
import { loadingEnd } from './loading';
import {
  pageLoadingStart, pageLoadingEnd,
} from './pageLoading';

import { httpFetchPackageDocuments } from './fetchDocuments.http';
import { clearPages } from './clearPages';

interface Params {
  sourceId?: number,
  pageNumber?: number,
  packageId?: number | string,
  sortData?: SortListItem
}

export interface RequestParams {
  sourceId: number,
  pageNumber: number,
  sort?: SortListItem
}

export class FetchPackageDocumentsThunk {

  getState;

  private notificationId = 'FETCH_PACKAGE_DOCUMENTS';

  private readonly dispatch: Dispatch;

  private sourceId: number;

  private pageNumber: number;

  private searchId: number;

  constructor(dispatch) {
    this.dispatch = dispatch;
    this.sourceId = null;
    this.pageNumber = null;
    this.searchId = null;
  }

  public async invoke(params: Params) {
    const state = this.getState().packages;
    this.sourceId = params.sourceId || state.packages.activeItemId;
    this.pageNumber = params.pageNumber || state.documents.currentPageNumber;
    this.searchId = state.packages.searchId;

    this.dispatch(pageLoadingStart.createAction());

    if (params.sortData) {
      await this.changeSort(params.sortData);

      return;
    }

    const hasPage = state.documents.pages[this.pageNumber];

    if (hasPage) {
      this.dispatchChangePageNumber();

      return;
    }

    await this.getNewPage();
  }

  private async getNewPage() {
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      searchId: this.searchId,
      sort: getActiveItem<SortListItem>(this.getState().documents.documentsSort),
    };

    const response = await this.fetchDocuments(requestParams);
    this.dispatchGetDocumentsSucceed(response);
    this.dispatchChangePageNumber();
  }

  private async changeSort(sort) {
    this.dispatch(selectDocumentsSort.createAction(sort));
    const requestParams = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      searchId: this.searchId,
      sort,
    };

    const response = await this.fetchDocuments(requestParams);
    this.dispatch(clearPages.createAction());
    this.dispatchGetDocumentsSucceed(response);
    this.dispatchChangePageNumber();
  }

  private async fetchDocuments(requestParams: RequestParams) {
    try {
      return await httpFetchPackageDocuments(requestParams);
    } catch (error) {
      notifierManager.fail({ id: this.notificationId, message: 'В подборку внесены изменения. Обновите страницу' });
      this.removeNotification();
      throw Error(error.status);
    }
  }

  private removeNotification = () => {
    setTimeout(() => {
      notifierManager.remove({ id: this.notificationId });
    }, 5000);
  };

  private dispatchGetDocumentsSucceed(result) {
    const actionData = {
      sourceId: this.sourceId,
      pageNumber: this.pageNumber,
      result,
    };

    this.dispatch(fetchDocuments.createAction(actionData));
  }

  private dispatchChangePageNumber() {
    const actionData = {
      pageNumber: this.pageNumber,
    };

    this.dispatch(changePageNumber.createAction(actionData));
    this.dispatch(loadingEnd.createAction());
    this.dispatch(pageLoadingEnd.createAction());
  }

}

export async function fetchPackageDocumentsThunk(dispatch, params: Params) {
  const thunk = new FetchPackageDocumentsThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  await thunk.invoke(params);
}

export default fetchPackageDocumentsThunk;
