import React, { useState } from 'react';

import { EmptyAvatar } from './emptyAvatar';

import s from './avatar.style';

type AvatarProps = {
  size?: number;
  src: string;
};

export const Avatar = ({ src, size = 32 }: AvatarProps) => {
  const [isHeightDominant, setHeightDominant] = useState(true);

  const handleLoad = ({ target: img }) => {
    const height = img.offsetHeight;
    const width = img.offsetWidth;

    if (height >= width) {
      setHeightDominant(true);
    } else {
      setHeightDominant(false);
    }
  };

  const renderAvatar = () => {
    if (!src) {
      return <EmptyAvatar size={size} />;
    }

    return <img onLoad={handleLoad} src={src} alt="avatar" />;
  };

  return (
    <s.Root
      size={size}
      isHeightDominant={isHeightDominant}
    >
      {renderAvatar()}
    </s.Root>
  );
};

export default Avatar;
