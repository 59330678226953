import { connect } from 'react-redux';

import { getParamsFromUrl } from '@utils/utils';
import { BootstrapThunk } from '@bootstrap/bootstrap.thunk';
import { resetChronologyDocuments } from '@layouts/chronology/redux/resetChronologyDocuments';
import { resetDocumentsSortSelect } from '@layouts/documents/redux/resetDocumentsSortSelect';

import { resetToInitial } from './redux/resetToInitial';

import { fetchAuthorPackagesThunk } from './sidebar/redux/fetchAuthorsPackages.thunk';
import { fetchSettingsThunk } from './workspace/documents/redux/fetchSettings.thunk';
import { AuthorPackages } from './authorPackages';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onInit() {
    const { packageId } = getParamsFromUrl(ownProps);

    const bootstrap = new BootstrapThunk(dispatch);
    bootstrap.invoke();

    fetchSettingsThunk(dispatch);
    fetchAuthorPackagesThunk(dispatch, { id: packageId });
  },
  onResetToInitial() {
    dispatch(resetToInitial.createAction());
    dispatch(resetChronologyDocuments.createAction());
    dispatch(resetDocumentsSortSelect.createAction());
  },
});

export const AuthorPackagesConnect = connect(
  null,
  mapDispatchToProps,
)(AuthorPackages);

export default AuthorPackagesConnect;
