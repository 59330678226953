import { RESTPath } from '@router/path.rest';
import http from '@http';

import {
  Id,
  Access,
} from '@pages/packages/redux/users/state';

export interface RequestParams {
  sourceId: Id,
  userId: Id;
  access: Access;
}

export function httpChangeAccess(requestParams: RequestParams): Promise<any> {
  const url = RESTPath.packages.setAccess({ sourceId: requestParams.sourceId });

  const data = {
    user_id: requestParams.userId,
    access: requestParams.access,
  };

  const request = http.post({
    url,
    data,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpChangeAccess;
