import { connect } from 'react-redux';

import { Profile } from './profile';

const mapStateToProps = (state) => {

  const { caselook, regulation } = state.currentUser.licenses;

  const caselookLicense = caselook.isActive;
  const monitoringLicense = regulation.isActive;
  const multiLicense = caselookLicense && monitoringLicense;

  return {
    shouldRenderNotificationTab: caselookLicense || multiLicense,
  };
};

export const ProfileConnect = connect(
  mapStateToProps,
)(Profile);

export default ProfileConnect;
