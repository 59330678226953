import { Map } from 'immutable';

export type Id = number;
export type Loading = boolean;

export enum Access {
  default = 'default',
  open = 'open',
  closed = 'closed',
}

export type AccessId = Access.default | Access.open | Access.closed;

export interface UserDTO {
  id: Id;
  name: string;
  email: string;
  avatar: string;
  access: AccessId;
  access_date: string;
  is_owner: boolean;
}

export interface User {
  id: Id;
  name: string;
  email: string;
  avatar: string;
  access: AccessId;
  accessDate: string;
  isOwner: boolean;
}

export class StateBuilder {

  private state: {
    defaultAccess: AccessId;
    itemsList: Id[];
    itemsMap: Map<Id, User>;
    loading: Loading;
  };

  constructor(state) {
    this.state = state;
  }

  static createState() {
    return {
      defaultAccess: Access.closed,
      itemsList: [],
      itemsMap: Map(),
      loading: true,
    };
  }

  public addUser(item: UserDTO) {
    const user = this.buildUser(item);
    this.addToItemsList(user.id);
    this.addToItemsMap(user.id, user);
  }

  public buildUsers(items) {
    items.forEach((item) => {
      this.addUser(item);
    });
  }

  public setUserAccess(id: Id, access: AccessId) {
    const user = this.state.itemsMap.get(id);
    const newUser = {
      ...user,
      access,
    };

    const itemsMap = this.state.itemsMap.set(id, newUser);

    this.state = {
      ...this.state,
      itemsMap,
    };
  }

  public setLoading(loading: Loading) {
    this.state = {
      ...this.state,
      loading,
    };
  }

  public setDefaultAccess(accessId: AccessId = Access.closed) {
    this.state = {
      ...this.state,
      defaultAccess: accessId,
    };
  }

  public getState() {
    return this.state;
  }

  private buildUser(item: UserDTO): User {
    return {
      id: item.id,
      name: item.name,
      email: item.email,
      avatar: item.avatar,
      access: item.access,
      accessDate: item.access_date || '-',
      isOwner: item.is_owner || false,
    };
  }

  private addToItemsList(id) {
    this.state.itemsList.push(id);
  }

  private addToItemsMap(id, user) {
    this.state.itemsMap = this.state.itemsMap.set(id, user);
  }

}

export default StateBuilder;
